import React from "react";
import envs from "../services/EnvsService";
import { useDispatch } from "react-redux";
import { addStudies } from "../state/handlers/order";
import { Button } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const ProfilesGrid = ({routines,disabled}) => {

    const dispatch = useDispatch();
    const {defaultEfectorId} = envs;
    
    const handleAdd = (studyTypes) => {
        dispatch(addStudies(studyTypes.map(s=>({study_type:{...s},efector_id : s.efector.id,efector : s.efector}))));
    };

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            hide:true,
            sortable: false,
            type: 'number'
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 85
        },
        {
            field: 'name',
            headerName: 'Descripcion',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 85,
        },
        {
            field: 'add',
            headerName: 'Agregar',
            sortable: false,
            renderCell: (params) => !disabled && <Button startIcon={<AddCircle style={{color:'#696969'}} onClick={()=>handleAdd(params.row.studyTypes)}/>}/>,
            
        }
    ];

    const data = {rows:routines, columns:columns}

    return(
            <DataGridPro
                style={{backgroundColor:'white'}}
                {...data}
                headerHeight={40}
                rowHeight={40}
                disableMultipleSelection
                disableColumnMenu
                disableColumnSelector
                disableMultipleColumnsSorting
                hideFooter
            />
    )

}

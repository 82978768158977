export const actionTypes = {
   editSample:'EDIT_SAMPLE',
   updateSamples:'UPDATE_SAMPLES',
   receiveSamples:'RECEIVE_SAMPLES',
   receiveSampleLocators:'RECEIVE_SAMPLE_LOCATORS',
   requestLastSampleLocator:'REQUEST_LAST_SAMPLE_LOCATOR',
   receiveLastSampleLocator:'RECEIVE_LAST_SAMPLE_LOCATOR',
   setSampleLocator:'SET_SAMPLE_LOCATOR',
   removeSample:'REMOVE_SAMPLE',
   verifySample:'VERIFY_SAMPLE',
   receiveSampleVerification:'RECEIVE_SAMPLE_VERIFICATION',
};


export const verifySample = (locator) => ({ type: actionTypes.verifySample, locator });
export const receiveSampleVerification = (verified,locator) => ({ type: actionTypes.receiveSampleVerification, verified,locator });
export const editSample = (id, locator) => ({ type: actionTypes.editSample, id, locator });
export const receiveSamples = (samples) => ({ type: actionTypes.receiveSamples, samples});
export const receiveSampleLocators = (samples) => ({ type: actionTypes.receiveSampleLocators, samples});
export const receiveLastSampleLocator = (locator) => ({ type: actionTypes.receiveLastSampleLocator, locator});
export const setSampleLocator = (locator) => ({ type: actionTypes.setSampleLocator, locator});
import React from 'react';
import { Box, Typography } from '@mui/material';

import History from './History';
import Result from './Result';
import ReferenceValue from './ReferenceValue';
import ReferenceText from './ReferenceText';

import '../../styles/Views.css';

const Test = ({ test }) => {


    return (
        <div id={`test_${test.id}`} className={"column-container"} >
            <div className={"column-container padding"}  >
                <Box fontWeight={600}>
                    <Typography style={{ textTransform: 'uppercase' }}>{test?.test_type_name}</Typography>
                </Box>
                <Typography variant="body1">{test?.method}</Typography>
            </div>
            <div className={"row-container"} >
                
                <div className="flex-2 padding" >
                    <Result result={test?.result}></Result>
                </div>
                
                {
                    test?.result.reference_text?.length > 0 &&
                    <div className="flex-2 padding">
                        <ReferenceText referenceText={test.result.reference_text}/>
                    </div>
                }
                        
                {
                    test?.result.reference_text?.length == 0 && test?.result?.reference_value && test?.result?.reference_value?.max > 0 &&
                    <div className="flex-2 padding">
                        <ReferenceValue result={test?.result}></ReferenceValue>
                    </div>
                }

            </div>

            {
                /*
                <div className={classes.historyContainer} >
                    {
                        !test.result.large && 
                        <History test={test} />
                    }
                </div>
                */
            }
        </div>
    );
};

export default Test;
import { createTheme } from '@mui/material/styles';
import { smgColorTheme, smgStatusColorTheme } from './smgTheme';
import { arencoColorTheme, arencoPantone, arencoStatusColorTheme } from './arencoTheme';
import { megaColorTheme, megaStatusColorTheme } from './megaTheme';

export const colorTheme = () => {

    switch(process.env.REACT_APP_THEME)
    {
        case("smg"):
            return smgColorTheme;
        case("arenco"):
            return arencoColorTheme;
        case("mega"):
            return megaColorTheme;
        default:
            return smgColorTheme;
    }
}


const statusTheme = () => {

    switch(process.env.REACT_APP_THEME)
    {
        case("smg"):
            return smgStatusColorTheme;
        case("arenco"):
            return arencoStatusColorTheme;
        case("mega"):
            return megaStatusColorTheme;
        default:
            return smgColorTheme;
    }
}

export default createTheme({
    palette: {
        arenco: {
            darkBlue: arencoPantone.darkBlue,
            mediumBlue: arencoPantone.mediumBlue,
            lightBlue: arencoPantone.lightBlue,
            green: arencoPantone.green,

        },
        status: {
            ...statusTheme()
        },
        primary: {
            main: colorTheme().primary,
        },
        secondary: {
            main: colorTheme().secondary,
        },
        text:{
            gray:"#696969"
        },
        background:{
            header:'#f4f4f4'

        },
        header: '#f4f4f4',
    },
    typography: {
        fontFamily:'Roboto',
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: '1rem',
                color: arencoPantone.darkBlue
            },
            body2: {
                fontSize: '0.8rem',
            }
        },
        
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiIconButton: {
            disableRipple: true,
            disableFocusRipple: true,
        }
    },
    styles: {
        iconButtons: {
            "&:hover": {
                backgroundColor: "transparent"
            }
        }
    },
    components: {
        MuiLinearProgress: {
          styleOverrides: {
            colorPrimary:{
                backgroundColor: "#F4F4F4"},
            barColorPrimary:{
                backgroundColor: "#03716A"},
            colorSecondary:{
                backgroundColor: "white"},
            barColorSecondary:{
                backgroundColor: "#03716A"},
            root:{
                height:"0.5em",
                borderRadius:"1em"
            }
          },
        }
      },
});
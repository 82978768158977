import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { IconButton,  LinearProgress,  Typography } from "@mui/material";


import '../styles/Views.css';
import { useTheme } from "@emotion/react";
import { fetchTechnicalNote } from "../state/handlers/catalogs";
import { selectCatalogs, selectTechnicalNote } from "../state/selectors/catalogs";

const TechnicalNote = ({studyType}) => {

    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        dispatch(fetchTechnicalNote(studyType.code));    
    }, []);

    const technicalNote = useSelector(selectTechnicalNote(studyType.code));
    const {status} = useSelector(selectCatalogs);

    return (
        <div className="column-container" style={{minWidth:'20em'}}>
            <Typography variant='h6' style={{marginBottom:'1em'}}>{studyType.name}</Typography>
            <Typography variant='h6' style={{backgroundColor:theme.palette.background.header}}>Información General</Typography>
            <Typography>{`Código ${studyType.code}`}</Typography>

            { 
                technicalNote && 
                <Fragment>
                    <Typography>{`Método ${technicalNote.method}`}</Typography>
                    <Typography>{`Días de procesamiento ${technicalNote.workingDays}`}</Typography>
                    <Typography>{`Informe ${technicalNote.dueDays}`}</Typography>
                    <Typography variant='h6' style={{backgroundColor:theme.palette.background.header}}>Información Técnica</Typography>
                    <Typography>{`Especialidad ${technicalNote.specialty}`}</Typography>
                    <Typography>{`Instrumento ${technicalNote.instrument}`}</Typography>
                    <Typography>{`Muestra ${technicalNote.sample}`}</Typography>
                    <Typography>{`Conservación y transporte ${technicalNote.transportation}`}</Typography>
                    <Typography variant='h6' style={{backgroundColor:theme.palette.background.header}}>Información Adicional</Typography>
                    <Typography>{`Indicaciones ${technicalNote.indications}`}</Typography>
                    <Typography>{`Interferencias ${technicalNote.interference}`}</Typography>
                    <Typography>{`Valores de referencia ${technicalNote.referenceValue}`}</Typography>
                </Fragment>
            }

            {
                !technicalNote && <Typography variant="h6" style={{marginTop:'2em', marginBottom:'2em'}}>Sin informacion</Typography>
            }

            {   
                status.loadingTechicalNote && <LinearProgress></LinearProgress>
            }

        </div>)
}

export default TechnicalNote;
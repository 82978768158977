export const megaPantone = {
    white:'#000000',
    orange:'#EF6C00',
    green:'#009688'
}

export const megaColorTheme = {
    primary:megaPantone.white,
    secondary:megaPantone.green
}

export const megaStatusColorTheme = {
    cancelled: '#ee7a7d',
    pending: '#FF9932',
    inProgress:'#666666',
    partial: '#3B3B3B',
    finished: '#3B3B3B',
    downloaded:'#009688'
}
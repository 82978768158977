import { actionTypes as orderActionTypes} from '../../actions/order';
import { actionTypes } from '../../actions/sample';


const matchSample = (sample, sampleType,efectorId) => {

    if(sample.sample_type.code == sampleType.code)
    {
        if(sample.efector.id == efectorId)
        {
            return true;
        }

        return false;
    }

    return false;
}

const samples = (state = [], action) => {
    switch (action.type) {
        case actionTypes.editSample:
            return [...state.map(sample=> sample.id != action.id ? sample: {...sample, locator:action.locator, verified:false, verifying:false, verifications:0})]
        case orderActionTypes.receiveOrder:
            return [...action.samples.map(s=>({...s,verifications:0}))]
        case actionTypes.receiveSamples:
            return [...action.samples.map(s=>({...s,verifications:0}))]
        case orderActionTypes.clearOrder:
            return []
        case actionTypes.verifySample:
            const samplesToVerify = [...state];
            
            samplesToVerify.forEach(sample =>{

                if(action.locator == sample.locator)
                {
                    sample.verified = false;
                    sample.verifying = true;
                }
            })
        
            return samplesToVerify;
        case actionTypes.receiveSampleVerification:
            const samplesVerication = [...state];
            
            samplesVerication.forEach(sample =>{

                if(action.locator == sample.locator)
                {
                    sample.verified = action.verified;
                    sample.verifying = false;
                    sample.verifications=sample.verifications+1;
                }
            })
        
            return samplesVerication;
        case actionTypes.receiveSampleLocators:
            
            const samples = [...state];
            
            samples.forEach(sample =>{

                if(action.samples.find(s=>s.id === sample.id))
                {
                    sample.locator = action.samples.find(s=>s.id === sample.id).locator;
                    sample.verified = true;
                }
            })
        
            return samples;

        case orderActionTypes.mergeSample:
            

            let cleaned = state.map(
                s=>(s.sample_type.code == action.oldSample.sample_type.code && s.efector_id == action.oldSample.efector_id) ? 
                {...s,studies:[...s.studies.filter(st=>st.study_type.code != action.study.study_type.code)]}:
                {...s}
            )

            return cleaned.map(
                s=>s.sample_type.code == action.sample.sample_type.code  && s.efector_id == action.sample.efector_id ? 
                {...s,studies:[...s.studies,action.study]}:
                {...s}
            )
    
        case orderActionTypes.addStudy:
            
            const studyTypesToAdd = !action.study.study_type.isRoutine ? [action.study.study_type] : action.study.study_type.study_types;
            
            studyTypesToAdd.forEach(studyTypeToAdd => {
                studyTypeToAdd.sample_types.forEach(sampleType => {
                    if(state.some(sample=>matchSample(sample,sampleType, studyTypeToAdd.efector.id))){
                        const sampleOnState = state.filter(sample=>matchSample(sample,sampleType, studyTypeToAdd.efector.id))[0];
                        state=[

                            ...state.map(sample=> !matchSample(sample,sampleType,studyTypeToAdd.efector.id) ? 
                                sample : 
                                {...sampleOnState, 
                                    studies: [
                                        ...sampleOnState.studies.filter(study=>study.study_type.code != studyTypeToAdd.code), 
                                        {study_type:{...studyTypeToAdd}}]}), 
                        ];
                    }   
                    else{
                        state=[
                                ...state, 
                                {
                                    verified:false, 
                                    verifications:0, 
                                    efector: studyTypeToAdd.efector, 
                                    efector_id : studyTypeToAdd.efector.id,
                                    sample_type:{...sampleType}, 
                                    studies:[{study_type:{...studyTypeToAdd}}]}
                        ];
                    }
                });
            });
            
            return state
        case orderActionTypes.removeStudy:
        
            action.study.samples.forEach(sample => {

                const {sample_type} = sample;

                if(state.some(sample=>sample.sample_type.code == sample_type.code && sample.efector_id == action.study.efector_id)){
                    const sampleOnState = state.filter(sample=>sample.sample_type.code == sample_type.code && sample.efector_id == action.study.efector_id)[0];
                    
                    if (sampleOnState.studies.length === 1){
                        state=[...state.filter(sample=> sample != sampleOnState)];
                    }
                    else{
                        state=[...state.filter(sample=> sample != sampleOnState), {...sampleOnState, studies:[...sampleOnState.studies.filter(study=>study.study_type.code !== action.study.study_type.code)]}];
                    }
                }
            });
            
            return state

        case orderActionTypes.editStudy:
        
            const sampleType = action.study.study_type.sample_types[0];
            const notRequiredSamples = [
                ...state.filter(sample=> sample.sample_type.code != sampleType.code),
                ...state.filter(sample=> sample.sample_type.code == sampleType.code && sample.efector.id != action.oldEfectorId),
            ];

            let matchedSample = state.filter(sample=>  sample.efector.id == action.oldEfectorId && sample.sample_type.code == sampleType.code)[0];
                matchedSample.studies = [...matchedSample.studies.filter(s => s.study_type.code != action.study.study_type.code)];

            let newSample = {
                verified:false, 
                verifications:0, 
                efector: action.study.efector, 
                efector_id : action.study.efector.id,
                sample_type:sampleType, 
                studies:[action.study]
            };

            if (notRequiredSamples.some(sample => sample.sample_type.code ==  newSample.sample_type.code && sample.efector.id == action.study.efector.id)){
                
                return [
                    ...notRequiredSamples.map(sample => sample.sample_type.code ==  newSample.sample_type.code && sample.efector.id == action.study.efector.id ? 
                        {...sample, studies:[...sample.studies, ...newSample.studies]}:
                        sample),
                    matchedSample,
                ];
            }

            return [...notRequiredSamples, matchedSample, newSample]
        default:
            return state
    }
}

export default samples;


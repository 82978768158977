import { Box, Typography } from '@mui/material';
import React from 'react';
import Test from './Test';
import '../../styles/Views.css';
import Status from '../Status';

const Study = ({ study, sample }) => {
    return (
        <div id={`study_${study.id}`} className={"column-container"} style={{ borderColor: '#f4f4f4', borderStyle: 'solid', borderWidth: '0.5', borderRadius: '5'  }}>
            <div className="row-container align-center" style={{ padding: '1em' }}>
                <div className='column-container flex-3'>
                    <Box fontWeight={600}>
                        <Typography variant="h5">{study?.study_type_name}</Typography>
                    </Box>
                    <Typography>{study?.method}</Typography>
                </div>
                <div className='grow'></div>
                <Status status={study.status}></Status>
                <div className='flex-3 align-end justify-center'>
                    <Box fontWeight={600}>
                        <Typography align='end' color='text.gray' variant="h5">{`${sample.locator} ${sample.sample_type.name}`}</Typography>
                    </Box>
                </div>
            </div>
            {study?.tests?.map(test => <Test key={test.id} test={test} />)}
        </div>
    );
};

export default Study;
import React from 'react';
import Router from '../components/Router';
import TopBar from '../components/TopBar';
import Menu from '../components/Menu';


const Layout = () => {

    const open = true;

    return (
        <div style={{ display: 'flex', flexFlow: 'column', height: '100vh', overflow: 'hidden' }}>
            <TopBar />
            <Menu open={open}/>
            <div style={{ minHeight: '4em' }}></div>
            <div style={{ height:'100%', marginLeft: open ? '15em' : '0em'}}>
                <Router />
            </div>
        </div >
    );
};

export default Layout;
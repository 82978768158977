import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editSample } from "../state/actions/sample";
import { Button, CircularProgress, Divider,  TextField, Typography } from "@mui/material";
import { MeasurementCard } from "./MeasurementCard";
import { Delete, Edit, Report, Verified, Warning } from "@mui/icons-material";
import { clearSampleLocator, customerClearSampleLocator, customerRemoveSample, removeSample, verifyCustomerSampleLocator, verifySampleLocator } from "../state/handlers/samples";
import { selectProfile } from "../state/selectors/profile";

export const SampleCard = ({index,order,sample,samples,disabled, duplicated, selected,next}) => {

    const profile = useSelector(selectProfile);
    const isCustomer = profile.data?.role.code === 'customer';
    const customerId = profile.data?.customer?.id;
    const ref = useRef(null)

 
    const dispatch = useDispatch();

    useEffect(() => {
        if(selected)
        {
            ref.current?.focus();
        }
    }, [selected]);
 
    const handleChange = (event) => {
        dispatch(editSample(sample.id, event.target.value));
    }

    const handleEnter = (event) => {
        if(event.key === 'Enter' || event.keyCode === 13) {
            if(sample?.locator !== '' && !duplicated){
                handleVerify(sample.locator);
                next();
            }
        }
    }

    const handleVerify = (locator) =>{
        if (isCustomer){
            dispatch(verifyCustomerSampleLocator(customerId, locator));
        }
        if (!isCustomer){
            dispatch(verifySampleLocator(order.customer_id,locator));
        }
    }

    const handleClearLocator = (sampleId) => {
        if (isCustomer){
            dispatch(customerClearSampleLocator(customerId, sampleId));
        }
        if (!isCustomer){
            dispatch(clearSampleLocator(sampleId));
        }
    };

    const handleRemove = (sampleId) => {
        if (isCustomer){
            dispatch(customerRemoveSample(customerId, sampleId));
        }
        if (!isCustomer){
            dispatch(removeSample(sampleId));
        }
    };

    return(
        <div>
            <div className="row-container align-center">
                <div style={{flex:0.5}}>
                    <Typography variant="h3">{index+1}</Typography>
                </div>
                
                <div style={{flex:2}}>
                    <Typography variant="h5">{`${sample.sample_type.name} / ${sample?.efector?.name}`}</Typography>
                    
                </div>
                <div style={{flex:1}}>
                    <TextField 
                        variant="outlined" 
                        value={sample.locator || ''} 
                        size="small"
                        inputRef={ref}
                        placeholder="Localizador"
                        onChange={handleChange}
                        onKeyDown={handleEnter}
                        disabled={disabled || sample.filled}
                        
                    />
                </div>
                <div style={{flex:1}}>                    
                    {
                        !duplicated && !sample.verified && sample.locator != '' && sample.locator &&
                        <div className="column-container align-center">
                            {
                                !sample.verifying &&
                                <Button 
                                    onClick={()=>handleVerify(sample.locator)}
                                    endIcon={<Verified/>}
                                    variant="contained"
                                    sx={{backgroundColor:'#EF6C00'}}
                                >
                                    Verificar
                                </Button>

                            }
                            {
                                sample.verifying &&
                                <CircularProgress color="secondary"/>
                            }
                        </div>
                    }
                    {
                        sample.verified &&
                        <div className="column-container align-center">
                            <Verified color="secondary" sx={{marginBottom:'0.5em'}}></Verified>
                            <Typography variant="body1">Verificada</Typography>
                        </div>
                    }
                    {
                        !sample.verified && duplicated &&
                        <div className="column-container align-center">
                            <Report color="secondary" sx={{marginBottom:'0.5em'}}></Report>
                            <Typography variant="body1">Duplicada</Typography>
                        </div>
                    }

                </div>
                <div style={{flex:1}}>                    
                    {
                        !sample.verified && sample.verifications > 0 &&
                        <div className="column-container align-center">
                            <Report sx={{color:'#EF6C00',marginBottom:'0.5em'}}></Report>
                            <Typography variant="body1">Numero invalido</Typography>
                        </div>
                    }
                </div>
                <div style={{flex:1}}>                    
                    {
                        !sample.studies?.length > 0 &&
                        <div className="column-container align-center">
                            <Warning color="secondary" sx={{color:'#EF6C00', marginBottom:'0.5em'}}></Warning>
                            <Typography variant="body1">Muestra sin estudios</Typography>
                        </div>
                    }
                </div>
                <div style={{flex:1}}>                    
                    {!sample.studies?.length > 0 ?
                        <Button 
                            onClick={()=>handleRemove(sample.id)}
                            endIcon={<Delete/>}
                            variant="contained"
                            color="secondary"
                        >
                            Eliminar
                        </Button>
                    : sample.filled && 
                        <Button 
                            onClick={()=>handleClearLocator(sample.id)}
                            endIcon={<Edit/>}
                            variant="outlined"
                            color="secondary"
                        >
                            Editar
                        </Button>
                    }
                </div>
                <div className="row-container margin">
                    {
                        sample.measurements.length > 0 && sample.measurements.map(measurement => <MeasurementCard key={measurement.id} measurement={measurement}/>)
                    }
                </div>
            </div>

            <Divider style={{marginTop:'1em',marginBottom:'1em'}}/>

        </div>
    )
};
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {PublicClientApplication } from '@azure/msal-browser';
import HttpService from './services/HttpService';
import envs from './services/EnvsService';
import { base } from './constants/base';
import AuthContext from './context/AuthCotext';

const {azureClientId,azureTenantId, authHandler} = envs;

const pca = authHandler === 'PASSPORT' ? 
    null :
    new PublicClientApplication({
        auth:{
            clientId: azureClientId,
            authority:`https://login.microsoftonline.com/${azureTenantId}`,
            redirectUri:'/'
        },
        cache: {
            cacheLocation: "localStorage"
        },
    });

new HttpService(pca, base);

ReactDOM.render(
    <React.StrictMode>
        <AuthContext.Provider value={{authHandler: authHandler}}>
            <App msalInstance={pca}/>
        </AuthContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();

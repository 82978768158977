import { actionTypes } from '../actions/routines';
import HttpService from "../../services/HttpService";

export const setCurrentRoutine = (routine) => ({ type: actionTypes.setRoutine, routine });
export const receiveRoutines = (routines) => ({ type: actionTypes.receiveRoutines, routines });
export const receiveRoutine = (routine) => ({ type: actionTypes.receiveRoutine, routine });
export const receiveRoutineStudies = (id, codes) => ({ type: actionTypes.receiveRoutineStudies, id, codes });

export const fetchRoutines = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchRoutines });

        return HttpService.getInstance().getData(`resource/routines`, 'user-token')
            .then(response => {
                dispatch(receiveRoutines(response.data));
        })
    }
}

export const createRoutine = (routine) => {
    return (dispatch,getState) => {

        dispatch({ type: actionTypes.createRoutine });
        const customerId = getState().profile.data.customer_id;

        return HttpService.getInstance().postData({customer_id:customerId, ...routine},`resource/routines`, 'user-token')
            .then(response => {
                dispatch(receiveRoutine(response.data));
        })
    }
}

export const updateRoutine = (routine) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateRoutine });

        return HttpService.getInstance().putData(routine,`resource/routines/${routine.id}`, 'user-token')
            .then(response => {
                dispatch(receiveRoutine(response.data));
        })
    }
}

export const addRoutineStudy = (id,code) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.addRoutineStudy});
        const body = {};

        return HttpService.getInstance().postData(body,`resource/routines/${id}/study_types/${code}`, 'user-token')
            .then(response => {
                dispatch(receiveRoutineStudies(id,response.data));
        })
    }
}

export const removeRoutineStudy = (id,code) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.removeRoutineStudy});
        const body = {};

        return HttpService.getInstance().deleteData(body,`resource/routines/${id}/study_types/${code}`, 'user-token')
            .then(response => {
                dispatch(receiveRoutineStudies(id, response.data));
        })
    }
}
import React, { Fragment, useEffect, useState } from 'react';
import {  Visibility } from '@mui/icons-material';
import { Button, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import UserDialog from '../components/UserDialog';
import { fetchCustomers } from '../state/handlers/catalogs.customer';
import { fetchUsers, setUser } from '../state/handlers/catalogs.user';
import { searchUsers, selectUsersCatalog } from '../state/selectors/catalogs.users';


const User = () => {

    const [filter, setFilter] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
 
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchCustomers());
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number',
            hide:true,
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: false,
            type: 'string',
            flex: 1,
            width: 120,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 1,
            minWidth: 110
        },
        {
            field: 'customer',
            headerName: 'Cliente',
            sortable: false,
            flex: 1,
            minWidth: 110,
            renderCell: (params) => params.row.customer?.name,
        },
        {
            field: 'roles',
            headerName: 'Rol',
            sortable: false,
            flex: 0.5,
            valueGetter:(params)=>params.row.role?.code,
            minWidth: 110
        },
        {
            field: 'view',
            headerName: 'Ver',
            sortable: false,
            renderCell: (params) => <Button onClick={()=>handleSelect(params.row)} startIcon={<Visibility style={{color:'#696969'}}/>}/>,
        }
    ];

    const {current,loading} = useSelector(selectUsersCatalog);
    const users = useSelector(searchUsers(filter))

    const data = {rows:users, columns:columns};

    const handleSelect = (user) => {
        dispatch(setUser(user));
        setOpenDialog(true);
    }

    const handleClose = (refresh) => {
        setOpenDialog(false);
        refresh && dispatch(fetchUsers());
    } 

    return ( 
        <Fragment>

            <div className="column-container all-height">
                <div className="header row-container align-center">
                    <div>
                        <Typography variant="h5" color="text.black">
                            <Box fontWeight={750}>Usuarios</Box>
                        </Typography>
                    </div>
                    <div className="grow"></div>
                    <div className="grow margin">
                        <TextField 
                            placeholder="Buscar" 
                            variant="standard" 
                            fullWidth
                            size="small"
                            onChange={(event)=>setFilter(event.target.value)}
                        />
                    </div>
                    <div  className='margin'>
                            <Button 
                                variant="contained"
                                color="secondary"
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                                onClick={()=>handleSelect({})}>
                                    <Typography>
                                        Nuevo usuario
                                    </Typography>
                            </Button>
                    </div>
                </div>

                <div>
                    {
                        loading && <LinearProgress/>
                    }
                </div>

                <div className="body grow padding">
                    <DataGridPro
                        {...data}
                        headerHeight={40}
                        rowHeight={40}
                        disableMultipleSelection
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleColumnsSorting
                        hideFooter
                    />
                </div>
            </div>

            {
                openDialog  && <UserDialog open={openDialog} refresh={()=>handleClose(true)} close={()=>handleClose(false)} values={current}/>
            }
        </Fragment>
     );
}
 
export default User;
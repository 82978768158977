import { actionTypes } from "../actions/explorer";
import HttpService from "../../services/HttpService";

export const receiveExplorerSamples = (samples) => ({ type: actionTypes.receiveExplorerSamples, samples });

export const fetchExplorerSamples = (filter) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchExplorerSamples });
        return HttpService.getInstance().getData(`explorer?${filter}`, 'user-token')
            .then(response => {
                dispatch(receiveExplorerSamples(response.data));
        })
    }
}

export const fetchCustomerExplorerSamples = (customerId,filter) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchExplorerSamples });
        return HttpService.getInstance().getData(`customers/${customerId}/explorer?${filter}`, 'user-token')
            .then(response => {
                dispatch(receiveExplorerSamples(response.data));
        })
    }
}

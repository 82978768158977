import { actionTypes} from '../../actions/order';
import { actionTypes as patientActionTypes } from '../../actions/patient';
import { actionTypes as samplesActionTypes } from '../../actions/sample';
import { actionTypes as measurementsActionTypes } from '../../actions/measurement';

const template = {
    loadingOrder:false, 
    loadingPatient:false, 
    loadingSamples:false, 
    loadingMeasurements:false, 
    savedSamples:false, 
    savedMeasurements:false,
    editedOrder:false,
    editedSamples:false,
    loadedPatient:false,
}

const status = (state = template, action) => {
    switch (action.type) {
        case actionTypes.clearOrder:
            return template
        case actionTypes.fetchOrder:
            return {...state, loadingOrder:true}
        case actionTypes.addStudy:
            return {...state, editedOrder:true}
        case actionTypes.editOrder:
            return {...state, editedOrder:true}
        case actionTypes.editStudy:
            return {...state, editedOrder:true}
        case actionTypes.removeStudy:
            return {...state, editedOrder:true}
        case patientActionTypes.setPatient:
            return {...state, editedOrder:true}
        case actionTypes.mergeSample:
            return {...state, editedOrder:true}
        case patientActionTypes.clearPatient:
            return {...state, editedOrder:true}
        case actionTypes.createOrder:
            return {...state, loadingOrder:true}
        case actionTypes.updateOrder:
            return {...state, loadingOrder:true}
        case patientActionTypes.createPatient:
            return {...state, loadingPatient:true}
        case actionTypes.receiveOrder:
            return {...state, loadingOrder:false,loadedPatient:true, editedOrder:false}
        case patientActionTypes.updatePatient:
            return {...state, loadingPatient:true,loadedPatient:true}
        case patientActionTypes.receivePatient:
            return {...state, loadingPatient:false,loadedPatient:true}
        case samplesActionTypes.updateSamples:
            return {...state, loadingSamples:true, editedSamples:false}
        case samplesActionTypes.editSample:
            return {...state, editedSamples:true}
        case samplesActionTypes.receiveSamples:
            return {...state, loadingSamples:false, savedSamples:true, editedSamples:false}
        case samplesActionTypes.receiveSampleLocators:
            return {...state, loadingSamples:false, savedSamples:false, editedSamples:true}
        case measurementsActionTypes.updateMeasurements:
            return {...state, loadingMeasurements:true}
        case measurementsActionTypes.receiveMeasurements:
            return {...state, loadingMeasurements:false, savedMeasurements:true}
        default:
            return state
    }
}

export default status;
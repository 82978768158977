import { InputAdornment, MenuItem, TextField } from '@mui/material';
import React from 'react';

const MultiData = ({ code, type, layout, value, classes, handleChange }) => {
    return (
        <TextField
            id="code"
            variant="outlined"
            select={layout.type === 'list'}
            type={layout.type === "date" ? layout.type : undefined}
            className={{ ...classes }}
            value={value}
            fullWidth
            size="small"
            onChange={(event) => handleChange(event)}
            InputProps={layout.label && {
                endAdornment: <InputAdornment position="end">{layout.label}</InputAdornment>,
            }}
        >
            {
                layout.type === 'list' &&
                layout.values &&
                layout.values.map(
                    item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>;
                    })
            }
        </TextField>
    );
};


export default MultiData;
import { actionTypes } from '../actions/measurementTypes';
import HttpService from "../../services/HttpService";

export const setMeasurementType = (measurement_type) => ({ type: actionTypes.setMeasurementType, measurement_type });
export const receiveMeasurementTypes = (measurement_types) => ({ type: actionTypes.receiveMeasurementTypes, measurement_types });
export const receiveMeasurementType = (measurement_type) => ({ type: actionTypes.receiveMeasurementType, measurement_type });
export const receiveMeasurementStudyTypes = (codes) => ({ type: actionTypes.receiveMeasurementStudyTypes, codes });
export const receiveMeasurementStudyType = (code) => ({ type: actionTypes.receiveMeasurementStudyType, code });
export const receiveMeasurementSampleTypes = (codes) => ({ type: actionTypes.receiveMeasurementSampleTypes, codes });
export const receiveMeasurementSampleType = (code) => ({ type: actionTypes.receiveMeasurementSampleTypes, code });
export const addMeasurementStudyTypes = () => ({ type: actionTypes.addMeasurementStudyTypes});
export const addMeasurementSampleTypes = () => ({ type: actionTypes.addMeasurementSampleTypes});
export const clearMeasurementStudyTypes = () => ({ type: actionTypes.clearMeasurementStudyTypes});
export const clearMeasurementSampleTypes = () => ({ type: actionTypes.clearMeasurementSampleTypes});

export const fetchMeasurementStudyTypes = (id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchMeasurementStudyTypes });

        return HttpService.getInstance().getData(`resource/measurement_types/${id}/study_types`, 'user-token')
            .then(response => {
                dispatch(receiveMeasurementStudyTypes(response.data));
        })
        .catch((exception) => {
        })

    }
};

export const addMeasurementStudyType = (id, code) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.addMeasurementSampleTypes });

        const body = {study_type_code:code};
        return HttpService.getInstance().postData(body,`resource/measurement_types/${id}/study_types`, 'user-token')
            .then(response => {
                dispatch(fetchMeasurementStudyTypes(id));
            })
            .catch(exception => {
                
            })

    }
}

export const removeMeasurementStudyType = (id, code) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.removeMeasurementSampleTypes });

        const body = {study_type_code:code};
        return HttpService.getInstance().deleteData(body,`resource/measurement_types/${id}/study_types/${code}`, 'user-token')
            .then(response => {
                dispatch(fetchMeasurementStudyTypes(id));
            })
            .catch(exception => {
                
            })

    }
}

export const fetchMeasurementSampleTypes = (id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchMeasurementSampleTypes });

        return HttpService.getInstance().getData(`resource/measurement_types/${id}/sample_types`, 'user-token')
            .then(response => {
                dispatch(receiveMeasurementSampleTypes(response.data));
        })
        .catch(exception => {
            
        })

    }
};

export const addMeasurementSampleType = (id, code) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.addMeasurementSampleTypes });

        const body = {sample_type_code:code};
        return HttpService.getInstance().postData(body,`resource/measurement_types/${id}/sample_types`, 'user-token')
            .then(response => {
                dispatch(fetchMeasurementSampleTypes(id));
        })
        .catch(exception => {
            
        })

    }
}

export const removeMeasurementSampleType = (id, code) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.removeMeasurementSampleTypes });

        const body = {sample_type_code:code};
        return HttpService.getInstance().deleteData(body,`resource/measurement_types/${id}/sample_types`, 'user-token')
            .then(response => {
                dispatch(fetchMeasurementSampleTypes(id));
        })
        .catch(exception => {
            
        })

    }
}


export const fetchMeasurementTypes = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchMeasurementTypes });

        return HttpService.getInstance().getData(`resource/measurement_types`, 'user-token')
            .then(response => {
                dispatch(receiveMeasurementTypes(response.data));
        })
        .catch(exception => {
            
        })

    }
}

export const createMeasurementType = (measurement_type) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.createMeasurementType });

        return HttpService.getInstance().postData(measurement_type,`resource/measurement_types`, 'user-token')
            .then(response => {
                dispatch(receiveMeasurementType(response.data));
            })
            .catch(exception => {
                
            })

    }
}

export const updateMeasurementType = (measurement_type) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateMeasurementType });

        return HttpService.getInstance().putData(measurement_type,`resource/measurement_types/${measurement_type.id}`, 'user-token')
            .then(response => {
            dispatch(receiveMeasurementType(response.data));
        })
        .catch(exception => {
            
        })

    }
}
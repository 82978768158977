import { createSelector } from "reselect";

export const filterOrders = (filter) => {
    return createSelector(
        selectOrdersList,
        orders => filter === "" ? 
            orders : 
            orders.filter(order => 
                String(order.number).toLowerCase().includes(filter.toLowerCase()) || 
                order.batch_id.toLowerCase().includes(filter.toLowerCase()) ||
                order.patient?.last_name.toLowerCase().includes(filter.toLowerCase()) ||
                order.patient?.first_name.toLowerCase().includes(filter.toLowerCase())
            )
    )
}

export const selectSearchCriteria = (state) => {
    return state.resultsExplorer.searchCriteria;
}

export const selectOrdersList = (state) => {
    return state.resultsExplorer.list;
}

export const selectOrders = (state) => {
    return state.resultsExplorer;
}

export const selectCurrentResults = (state) => {
    return state.resultsExplorer.current;
}
import { Grid, Typography, Divider, Box } from '@mui/material';
import React, { Fragment } from 'react';

const Large = ({large}) =>
{
    return(
        <Fragment>
        {
            large.map(
                line => line.map(record => <Typography>{record}</Typography>))
        }
        </Fragment>
    )

}

const Result = ({ result }) => {


    return (

        <Grid direction="column">
            <Box fontWeight={520}>
                <Typography>Resultado</Typography>
            </Box>
            <Divider />
            <Grid item container spacing={1}>

                {
                    result.large &&
                    <Grid item>
                        <Large large={result.large} />
                    </Grid>
                }

                {
                    !result.large &&
                    <Grid item>
                        <Typography variant={isNaN(result?.value) ? "h4" : "h3"}>{ result?.value}</Typography>
                    </Grid>
                }
                {    
                    !result.large &&                    
                    <Grid item>
                        <Typography variant="body1">{result?.unit_of_measurement}</Typography>
                    </Grid>
                }

            </Grid>

            {
                result?.observation &&
                <Typography variant="body2">* {result?.observation}</Typography>
            }

        </Grid>
    );
};

export default Result;
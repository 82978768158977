import Cookies from 'js-cookie'
import axios from 'axios';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

class HttpService {

    constructor(msalInstance,base,authHandler){

        this.msalInstance = msalInstance;
        this.url = base;
        this.authHandler = authHandler;

        this.blobHandler = axios.create({
          baseURL: this.url,
          headers: {
            "Content-Type": "application/json",
          },
          responseType : 'blob'
          
        });

        this.handler = axios.create({
            baseURL: this.url,
            headers: {
              "Content-Type": "application/json",
            },
          });

        
          const requestInterceptor = (config) => {
            const token = this.getLocalToken();
  
            if (token) {
              config.headers["authorization"] = 'Bearer ' + token;
            }
            return config;
          };
  
  
          const responseInterceptor = async (err) => {
  
            const originalConfig = err.config;
  
            if (err.response) {
              // Access Token was expired
  
              if(this.authHandler === 'PASSPORT'){
                return Promise.reject(err);
              }
  
              if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
  
                try {
                  
                  await this.refreshToken();
  
                  const idToken = this.getLocalToken();
                  this.handler.defaults.headers.common["authorization"] = 'Bearer ' + idToken;
        
                  return this.handler(originalConfig);
  
                } catch (_error) {
                  if (_error.response && _error.response.data) {
                    return Promise.reject(_error.response.data);
                  }
        
                  return Promise.reject(_error);
                }
              }
        
              if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
              }
  
            }
  
            return Promise.reject(err);
  
          }

          
        this.handler.interceptors.request.use(
            requestInterceptor,
            (error) => {
              return Promise.reject(error);
            }
          );


        this.blobHandler.interceptors.request.use(
          requestInterceptor, 
          (error) => {
            return Promise.reject(error);
          }
        );
        
        this.blobHandler.interceptors.response.use(
          (res) => {
            return res;
          },
          responseInterceptor
        );

        this.handler.interceptors.response.use(
            (res) => {
              return res;
            },
            responseInterceptor
          );

        HttpService._instance = this;
        return HttpService._instance;
    }

    static getInstance(){
        return this._instance;
    }

    getLocalToken = () => {
        return Cookies.get('token');
    };

    getLocalUser = () => {
      return Cookies.get('user');
    };

    setLocalToken = (token) => {
        Cookies.set('token', token, { expires: 1 });
    };

    setLocalUser = (user) => {
        Cookies.set('user', user, { expires: 1 });
    };

    removeLocalToken = (token) => {
        Cookies.remove('token');
    };

    removeLocalUser = () => {
      Cookies.remove('user');
    };


    postData = async (item, added_url, tokenId = "") => {
        return this.handler.post(added_url,item);
    }

    putData = async (item, added_url, tokenId = "") => {
        return this.handler.put(added_url,item);
    }

    deleteData = async (added_url, tokenId = "") => {
        return this.handler.delete(added_url);
    }
    
    getData = async (added_url, tokenId = "", queryParams = "") => {
        const params = queryParams ? '?' + new URLSearchParams(queryParams).toString() : '';
        return this.handler.get(added_url + params)
    }

    getPDF = async (added_url, tokenId = "", queryParams = "") => {
        const params = queryParams ? '?' + new URLSearchParams(queryParams).toString() : '';
        return this.blobHandler.get(added_url + params)
    }

    getRequestOptions = (token, contentType = null) => {
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': contentType || 'application/json',
                'Accept': '*/*',
            }
        }
        return requestOptions;
    }

    refreshToken = async () => {

      const user = this.getLocalUser();
      const token = this.getLocalToken();

      const silentRequest = {
        scopes: ["openid"],
        account: this.getLocalUser()
      };

            if (!user && !token)
            {
              await this.msalInstance.loginRedirect(silentRequest);
              return;
            }

      try
      {
        await this.msalInstance.ssoSilent(silentRequest);
        return;
      }

      catch(err)
      {
        if (err instanceof InteractionRequiredAuthError)
        {
          await this.msalInstance.loginRedirect(silentRequest);
          return;
          }
      }
    }
}

export default HttpService;
import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";

import TechnicalNote from "../views/TecnicalNote";
import '../styles/Views.css';
import { Close } from "@mui/icons-material";

const TechnicalNoteDialog = ({studyType, open, onClose}) => {
    return(
        <Dialog open={open} >
            
                <div className="row-container">
                <DialogTitle>Ficha tecnica</DialogTitle>
                    <div className="grow"></div>
                    <Button style={{marginRight:'1em'}} onClick={()=>onClose()}><Close/></Button>
                </div>
            
            <DialogContent>
                <TechnicalNote studyType={studyType} />
            </DialogContent>
        </Dialog>

    )

};

export default TechnicalNoteDialog;

import { createSelector } from "reselect";

export const selectCustomersCatalog = (state) => {
    return state.catalogs.customers;
}

export const selectCustomers = (state) => {
    return state.catalogs.customers.list;
}

export const searchCustomers = (filter) => {
    return createSelector(
        selectCustomers,
        customers => filter === "" ? customers : customers.filter(customer => String(customer.code).toLowerCase().includes(filter.toLowerCase()) || customer.name.toLowerCase().includes(filter.toLowerCase())))
};


import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import ResultsView from "./ResultsView";

import '../../styles/Views.css';
import OrderData from "../common/OrderData";
import PatientData from "../common/PatientData";
import _ from "underscore";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import OrderStatus from "../OrderStatus";


export const ResultsDialog = ({open,handleClose,handleNext,handlePrevious,data,isFirst,isLast}) => {
    return (
        <Dialog open={open} fullScreen style={{padding:'2em'}}>
            <DialogTitle>
                <div className='row-container' style={{margin: '0.5em', borderColor: '#f4f4f4', borderStyle: 'solid', borderWidth: '0.5', borderRadius: '5' }}>
                    <div className='flex-2 padding'>
                        <OrderData order={data.order}/>
                    </div>
                    <div className='flex-2 padding'>
                        {
                            !_.isEmpty(data.order.patient) &&                        
                            <PatientData patient={data.order.patient}/>
                        }
                    </div>
                    <div className="column-container align-end justify-end padding">
                        <Button 
                            color="primary"
                            startIcon={<ArrowBack color="secondary" />}
                            variant='outlined'
                            disabled={isFirst} 
                            onClick={()=>handlePrevious()}
                            sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em', marginBottom:'1em'}}
                        >
                            <Box fontWeight="medium">
                                <Typography color="black">Anterior</Typography>
                            </Box>
                        </Button>
                        
                        <Button 
                            color="primary"
                            endIcon={<ArrowForward color="secondary" />}
                            variant='outlined'
                            disabled={isLast} 
                            onClick={()=>handleNext()}
                            sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em', marginBottom:'1em'}}
                        >
                                
                            <Box fontWeight="medium">
                                <Typography color="black">Siguiente</Typography>
                            </Box>
                        </Button>
                        <div className="grow"></div>
                        <Button 
                            color="primary"
                            variant='outlined'
                            onClick={()=>handleClose()}
                            sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em', marginBottom:'1em'}}
                        >
                                
                            <Box fontWeight="medium">
                                <Typography color="black">Cerrar</Typography>
                            </Box>
                        </Button>

                    </div>
                </div>

            </DialogTitle>
            <DialogContent>
                <ResultsView {...data}></ResultsView>
            </DialogContent>
        </Dialog>
    )
}
import { combineReducers } from 'redux';
import { actionTypes } from '../actions/ordersToSend';

const status = (state = {status:'waiting', waiting:3, sended:0, error:0}, action) => {
    switch (action.type) {
        case actionTypes.setOrdersToSend:
            return {status:'waiting', waiting:action.orders.length, sended:0, error:0}
        case actionTypes.clearOrdersToSend:
            return {status:'waiting', waiting:3, sended:0, error:0}
        case actionTypes.acceptOrder:
            return {...state, waiting:state.waiting-1, sended:state.sended+1}
        case actionTypes.rejectOrder:
            return {...state, waiting:state.waiting-1, error:state.error+1}
        default:
            return state
    }
}

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.setOrdersToSend:
            return action.orders
        case actionTypes.clearOrdersToSend:
            return []
        case actionTypes.sendOrder:
            return [...state.map(order => order.id == action.order.id ? {...order, status:'sending'}:order)]
        case actionTypes.acceptOrder:
            return [...state.map(order => order.id == action.order.id ? {...order, status:'sended'}:order)]
        case actionTypes.rejectOrder:
            return [...state.map(order => order.id == action.order.id ? {...order, status:'error',error:action.error}:order)]
        default:
            return state
    }
}
export default combineReducers({ status, list });


export const actionTypes = {
    fetchSampleRanges: 'FETCH_SAMPLE_RANGES',
    receiveSampleRange: 'RECEIVE_SAMPLE_RANGE',
    receiveSampleRanges: 'RECEIVE_SAMPLE_RANGES',
    clearSampleRanges : 'CLEAR_SAMPLE_RANGES',
    updateSampleRange:'UPDATE_SAMPLE_RANGE',
    createSampleRange:'CREATE_SAMPLE_RANGE'
};

export const clearSampleRanges = { type: actionTypes.clearSampleRanges };


import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Add, ArrowBack, ArrowForward,  AutoAwesome,  PersonAdd, Save, Search  } from "@mui/icons-material";
import { Avatar, Button, Divider, InputAdornment, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, parseISO } from 'date-fns';
import _ from "underscore";

import '../styles/Views.css';

import PatientModal from "../components/PatientModal";
import { clearOrder } from "../state/actions/order";
import { addStudy, createCustomerFastSample, fetchCustomerOrder, fetchOrder, updateCustomerOrder } from "../state/handlers/order";
import { selectCurrentOrder } from "../state/selectors/currentOrder";
import { setPatient } from "../state/actions/patient";
import { selectProfile } from "../state/selectors/profile";
import { selectBatchData } from "../state/selectors/batch";
import { searchStudies, searchStudiesBySampleType, selectCatalogs, selectCompleteRoutines} from "../state/selectors/catalogs";
import { translate } from "../helpers/genders";
import { StudiesGrid } from "./StudiesGrid";
import { ProfilesGrid } from "./ProfilesGrid";
import { OrderStudiesGrid } from "./OrderStudiesGrid";
import { lastSampleLocator, sampleLocators } from "../state/handlers/samples";
import { setSampleLocator } from "../state/actions/sample";


const FastSample = () => {

    const [tabIndex, setTabIndex] = useState(0);
    const [patientDialog, setPatientDialog] = useState(false);
    const [filter, setFilter] = useState('');
    
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {loading, order, patient, studies, sample, samples, measurements} = useSelector(selectCurrentOrder);
    
    const profile = useSelector(selectProfile);
    const batch = useSelector(selectBatchData);

    const studiesCatalog = useSelector(searchStudiesBySampleType(filter,sample?.sampleType));
    const routinesCatalog = useSelector(selectCompleteRoutines());
    const {efectors} = useSelector(selectCatalogs);

    const isCustomer = profile.data?.role.code === 'customer';
    const customerId = profile.data?.customer?.id;

    useEffect(() => {
        if(id)
        {
            if(isCustomer){
                dispatch(fetchCustomerOrder(customerId,id));
            }else{
                dispatch(fetchOrder(id));
            }
        }else{
            dispatch(clearOrder);
        }
    }, []);


    useEffect(() => {
        !id && dispatch(clearOrder);
    }, [id]);

    const handleReload = () => {
        if(id)
        {
            if(isCustomer){
                dispatch(fetchCustomerOrder(customerId,id));
            }else{
                dispatch(fetchOrder(id));
            }

        }else{
            dispatch(clearOrder);
        }
    }

    const handleNewOrder = () => {
        id && navigate('/order');
        !id && dispatch(clearOrder);
    }

    const handleClosePatientDialog = (patient) => {
        patient && dispatch(setPatient(patient));
        setPatientDialog(false);
    }

    const handleLastLocator = () => {
        dispatch(lastSampleLocator(customerId));
    };

    const handleBack = () => {
        navigate(`/batch/${batch.id}`);
    }

    const handleFindStudy = (event) => {
        if(event.key === 'Enter' || event.keyCode === 13) {
            const studyType = studiesCatalog.find(s=>s.code === filter);
            
            if(studyType){
                dispatch(addStudy({studyType:{...studyType}}));
                setFilter('');
            }
        }
    }

    const handleChangeSampleLocator = (value) => {
        dispatch(setSampleLocator(value));
    }

    const handleSave = () => {
        !id && dispatch(createCustomerFastSample(customerId, {...order, customer_id:customerId, batch_id:batch.id}, sample, studies, samples, measurements));
        id && dispatch(updateCustomerOrder(customerId, {...order, customer_id:customerId, batch_id:batch.id},studies, samples, measurements));
    }

    const canSave = studies.length && samples?.length === 1;

    return (
        <Fragment>
            <div className="column-container all-height">
                <div className="header row-container" style={{paddingTop:'1em', marginBottom:'1em'}}>
                    <div className="column-container " style={{paddingRight:'1em',width:'20em'}}> 
                        
                        <TextField 
                            id="sample_locator" 
                            placeholder="Localizador de muestra"
                            value={sample?.locator}
                            onChange={(event)=>handleChangeSampleLocator(event.target.value)}

                        />

                        <Typography id="sample_type">{sample?.sampleType?.name}</Typography>

                        <Button 
                            variant="contained"
                            color="secondary"
                            endIcon={<AutoAwesome/>}
                            onClick={()=>handleLastLocator()}
                            sx={{height:'2em', textTransform:'none', marginTop:'1em'}}>
                            <Box fontWeight="medium">
                                <Typography>Auto</Typography>
                            </Box>
                        </Button>

                    </div>

                    <Divider orientation="vertical"  sx={{ height:"5.5em"}} />

                    <div className="row-container" style={{marginLeft:'2em'}}>
                        <div className="column-container justify-center" style={{marginRight:'1em'}}>
                            <Avatar style={{height:'3em', width:'3em'}} onClick={()=>setPatientDialog(true)}>
                                <PersonAdd fontSize="large"/>
                            </Avatar>
                        </div>
                        <div className="column-container " style={{paddingRight:'1em',width:'20em'}}> 
                            
                            {
                                _.isEmpty(patient) &&
                                <Fragment>
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                </Fragment>

                            }
                            {   
                                !_.isEmpty(patient) &&                        
                                <Fragment>
                                    <Box fontWeight="medium">
                                        <Typography variant="h6" color="text.black">{`${patient.first_name} ${patient.last_name}`}</Typography>
                                    </Box>
                                    <Typography color="text.gray" variant="body2">{`N° ${patient.number}`}</Typography>
                                    <Typography color="text.gray" variant="body2">{`Fecha Nac. ${format(parseISO(patient.birth_day), 'dd/MM/yyyy')}`}</Typography>
                                    <Typography color="text.gray" variant="body2">{translate(patient.gender)}</Typography>
                                    
                                </Fragment>
                            }                    
                        </div>
                    </div>

                    <div className="grow"></div>

                    <div className="column-container align-right">
                        <Button 
                            color="primary"
                            startIcon={<ArrowBack color="secondary" />}
                            sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em', marginBottom:'1em'}}
                            onClick={()=>handleBack()}>
                                
                                <Box fontWeight="medium">
                                    <Typography>Volver al lote</Typography>
                                </Box>
                                
                        </Button>
                        <Button 
                            variant="outlined"
                            startIcon={<Add/>}
                            sx={{ textDecoration:'none', textTransform:"none", width:'15em'}}
                            onClick={()=>handleNewOrder()}
                        >
                            
                            <Box fontWeight="regular">
                                <Typography variant="body2">Muestra rápida</Typography>
                            </Box>
                            
                        </Button>

                        
                    </div>
                </div>

                <div className="row-container body grow">
                    <div className="column-container" style={{ flexGrow:4,marginRight:'1em'}}>
                        <TextField 
                            placeholder="Buscar estudio" 
                            size="small" 
                            variant="standard" 
                            fullWidth
                            value={filter || ''}
                            onChange={(event)=>setFilter(event.target.value)}
                            onKeyUp={(event)=>handleFindStudy(event)}
                            InputProps={{
                                endAdornment:(
                                <InputAdornment position="end">
                                    <Search/>
                                </InputAdornment>)}}
                        />
                        <Tabs 
                            value={tabIndex} 
                            onChange={(event, newValue)=>setTabIndex(newValue)} 
                            variant="fullWidth" 
                            style={{marginTop:'1em', backgroundColor:'#f4f4f4'}}
                        >
                            <Tab label="Listado completo" sx={tabIndex === 0 ? {textTransform:'none', backgroundColor:'white'}:{textTransform:'none'}}/>
                            <Tab label="Favoritos" sx={tabIndex === 1 ? {textTransform:'none', backgroundColor:'white'}: {textTransform:'none'}}/>
                            <Tab label="Perfiles" sx={tabIndex === 2 ? {textTransform:'none', backgroundColor:'white'}: {textTransform:'none'}}/>
                        </Tabs>

                        <div className="grow">
                            {tabIndex===0 && <StudiesGrid studiesCatalog={studiesCatalog} studies={studies}/>}
                            {tabIndex===1 && <StudiesGrid studiesCatalog={studiesCatalog.filter(study=>study.isFavorite)} studies={studies}/>}
                            {tabIndex===2 && <ProfilesGrid routines={routinesCatalog}/>}
                        </div>
                    </div>
                    <div className="column-container" style={{flexGrow:3, paddingTop:'2.8em'}}>
                        <Tabs 
                            value={0} 
                            onChange={(event, newValue)=>setTabIndex(newValue)} 
                            variant="fullWidth"
                            style={{backgroundColor:'#f4f4f4'}}
                        >
                            <Tab label="Estudios agregados" sx={{textTransform:'none'}}/>
                        </Tabs>
                        <div className="grow">
                            <OrderStudiesGrid  studies={studies} efectors={efectors} />
                        </div>
                    </div>
                </div>

                <div className="row-container justify-right" style={{paddingRight:'2em', marginTop:'0.5em', marginBottom:'0.5em'}}>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={()=>handleReload()}
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'1em', paddingRight:'1em',  width:'10em', marginBottom:'1em'}}>
                            <Box fontWeight="medium">
                                <Typography>Cancelar</Typography>
                            </Box>
                    </Button>
                   
                    <Button 
                        style={{marginLeft:'1em'}}
                        variant="contained"
                        color="secondary"
                        endIcon={<Save/>}
                        disabled={!canSave}
                        onClick={()=>handleSave()}
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em', width:'15em', marginBottom:'1em'}}>
                            
                            <Box fontWeight="medium">
                                <Typography>Guardar</Typography>
                            </Box>
                    </Button>
                </div>
            </div>

            {
                patientDialog &&
                <PatientModal open={patientDialog} onClose={handleClosePatientDialog} initPatient={{...patient}} />
            }

        </Fragment>

    )
}

export default FastSample;
import logoSmg from './../assets/logo_smg.png';
import logoMega from './../assets/logo_mega.png';
import logoArenco from './../assets/logo_arenco.png'

export const selectLogo = () =>
{
    switch(process.env.REACT_APP_THEME)
        {
            case('smg'):
                return logoSmg;
            case('mega'):
                return logoMega;
            case('arenco'):
                return logoArenco;
            default:
                return logoArenco;
        }
}
import { actionTypes } from '../actions/catalogs.user';
import HttpService from "../../services/HttpService";

const receiveUsers = (users) => ({ type: actionTypes.receiveUsers, users });
const receiveUser = (user) => ({ type: actionTypes.receiveUser, user });

export const setUser = (user) => ({ type: actionTypes.setUser, user });
export const clearUser = () => ({ type: actionTypes.clearUser});

export const fetchUsers = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchUsers });

        return HttpService.getInstance().getData(`resource/users`, 'user-token')
            .then(response => {
                dispatch(receiveUsers(response.data));
        })
    }
}

export const createUser = (user) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.saveUser });

        return HttpService.getInstance().postData({user:user},`resource/users`, 'user-token')
            .then(response => {
                dispatch(receiveUser(response.data));
        })
    }
}

export const updateUser = (user) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.saveUser });

        return HttpService.getInstance().putData({user:user},`resource/users/${user.id}`, 'user-token')
            .then(response => {
                dispatch(receiveUser(response.data));
        })
    }
}
import { createSelector } from "reselect";

export const selectUsersCatalog = (state) => {
    return state.catalogs.users;
}

export const selectUsers = (state) => {
    return state.catalogs.users.list;
}

export const searchUsers = (filter) => {
    return createSelector(
        selectUsers,
        users => filter === "" ? users : users.filter(user => user.email.toLowerCase().includes(filter.toLowerCase()) || user.name.toLowerCase().includes(filter.toLowerCase())))
};


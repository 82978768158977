import React from 'react';
import AzureLogin from './AzureLogin';
import PassportLogin from './PassportLogin';
import envs from '../services/EnvsService';


const Login = () => {

  const {authHandler} = envs;

  const component = () => {
        switch (authHandler) {
        case 'AZURE_AD': return <AzureLogin></AzureLogin>;
        case 'PASSPORT': return <PassportLogin></PassportLogin>;
        default: return <PassportLogin></PassportLogin>;
      }
    }
    return (component());
};

export default Login;
import { createSelector } from "reselect";

export const selectCatalogs = (state) => {
    return state.catalogs;
}

export const searchStudiesBySampleType = (filter,sampleType) => {
    
    return sampleType?.id ?
        createSelector(
            selectStudiesBySampleType(sampleType),
            studies => filter === "" ? studies : studies.filter(study => String(study.code).toLowerCase().includes(filter.toLowerCase()) || study.name.toLowerCase().includes(filter.toLowerCase()))) :
        createSelector(
            selectVisibleStudies,
            studies => filter === "" ? studies : studies.filter(study => String(study.code).toLowerCase().includes(filter.toLowerCase()) || study.name.toLowerCase().includes(filter.toLowerCase())))
};

export const searchStudies = (filter, efectorFilter) => {
    return createSelector(
        efectorFilter === 'all' ? selectVisibleStudies : efectorFilter === 'local' ? selectLocalVisibleStudies : selectExternalVisibleStudies,
        studies => filter === "" ? studies : studies.filter(study => String(study.code).toLowerCase().includes(filter.toLowerCase()) || String(study.alt_code).toLowerCase().includes(filter.toLowerCase()) || study.name.toLowerCase().includes(filter.toLowerCase())))
};

export const selectStudiesBySampleType = (sampleType) => {
    return createSelector(
        selectVisibleStudies,
        studies => studies.filter(study => study.sampleTypes.some(st => st.id === sampleType.id)))
};

export const selectStudiesByCodes = (codes) => {
    return createSelector(
        selectVisibleStudies,
        studies => studies.filter(study => codes.some(code => code === study.code)))
};

export const selectTechnicalNote = (code) => {
    return createSelector(
        selectStudies,
        studies => 
            {
               let founded = studies.filter(study => study.code === code);

               if (founded.length>0)
               {
                    return founded[0].technicalNote;
               }
               return undefined;
            })
};

export const selectStudies = (state) => {
    return state.catalogs.studies;
}

export const selectLocalVisibleStudies = (state) => {
    return state.catalogs.studies.filter(s=>s.isVisible === true && s.efector.local == 1);
}

export const selectExternalVisibleStudies = (state) => {
    return state.catalogs.studies.filter(s=>s.isVisible === true && s.efector.local == 0);
}

export const selectVisibleStudies = (state) => {
    return state.catalogs.studies.filter(s=>s.isVisible === true);
}

export const selectRoutines = (state) => {
    return state.catalogs.routines;
}

export const selectRoles = (state) => {
    return state.catalogs.roles;
}

export const selectCompleteRoutines = () => {
    return createSelector(
        selectRoutines,
        selectStudies,
        (routines,studies) => routines.list.map(r=>({...r, studyTypes:[...studies.filter(study => r.studyTypes.some(code => code === study.code))]})))
}

export const selectSamples = (state) => {
    return state.catalogs.sampleTypes;
}

export const selectSamplesByCodes = (codes) => {
    return createSelector(
        selectSamples,
        samples => samples.filter(sample => codes.some(code => code === sample.code)))
};

import { useTheme } from '@emotion/react';
import React, { Fragment, useState } from 'react';
//import { scroller } from 'react-scroll';



const Studies = ({ studies }) => {
    const [selected, setSelected] = useState(null);
    const theme = useTheme();

    const isSelected = (study, test) => selected && (selected === `study_${study?.id}` || selected === `test_${test?.id}`);

    const handleTestClick = (id) => {
    /*
        scroller.scrollTo(`test_${id}`, { spy: true, smooth: true, duration: 1000, delay: 300, containerId: 'results_container', });
        setSelected(`test_${id}`);
    */
    };

    const handleStudyClick = (id) => {
    /*
        scroller.scrollTo(`study_${id}`, { spy: true, smooth: true, duration: 1000, delay: 300, containerId: 'results_container', });
        setSelected(`study_${id}`);
    */
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {
                studies.map(study => {
                    return (
                        <Fragment>
                            <span style={{ backgroundColor: isSelected(study, null) ? '#f4f4f4' : 'white', paddingTop: '1em', paddingBottom: '1em', paddingLeft: '1em', borderColor: isSelected(study, null) ? theme.palette.primary.main : '#f4f4f4', borderWidth: 1, borderStyle: 'solid', borderRadius: 5, textTransform: 'capitalize' }} onClick={() => handleStudyClick(study.id)}>{study.study_type_name}{study.status == 'cancelled' && ' (Recitado) '}</span>
                            {
                                study.status != 'cancelled' &&
                                study.tests?.map(test => <span style={{ backgroundColor: isSelected(null, test) ? '#f4f4f4' : 'white', paddingTop: '1em', paddingBottom: '1em', paddingLeft: '3em', borderColor: isSelected(null, test) ? theme.palette.primary.main : '#f4f4f4', borderWidth: 1, borderStyle: 'solid', borderRadius: 5, height: '2em' }} onClick={() => handleTestClick(test.id)}>{test.test_type_name}</span>)
                            }
                        </Fragment>);
                })
            }
        </div>
    );
};

export default Studies;
import { actionTypes } from '../../actions/order';

const studies = (state = [], action) => {
    switch (action.type) {
        case actionTypes.clearOrder:
            return []
        case actionTypes.receiveOrder:
            return [...action.studies]
        
        case actionTypes.reAddStudy:
            return [...state.map(study => study.studyType.code === action.study.studyType.code ? {...study, mark_remove:false} : study)];

        case actionTypes.mergeSample:

            return state.map(
                s=>s.study_type.code == action.study.study_type.code ? 
                {...s,merged:true,samples:[...s.samples.filter(sm=>!(sm.sample_type.code == action.oldSample.sample_type.code && sm.efector_id == action.oldSample.efector_id)),action.sample]}:
                {...s}
            )

        case actionTypes.addStudy:

            const studyTypesToAdd = !action.study.study_type.isRoutine ? [action.study.study_type] : action.study.study_type.study_types;

            studyTypesToAdd.forEach(studyTypeToAdd => {
                if(!state.some(study=>study.study_type.code === studyTypeToAdd.code))
                {            
                    if (action.study.created_at)
                    {
                        state = [...state.studies.map(study => study.studyType.code !== studyTypeToAdd.studyType.code ? study : {...study, mark_remove:false})]
                    }
                    else
                    {
                        state = state.length ? 
                        [   ...state, 
                            {
                                id: Math.max(...state.map(study=>study.id))+1,
                                study_type:{...studyTypeToAdd},
                                samples:[...studyTypeToAdd.sample_types.map(st=>({efector:action.study.efector, efector_id:action.study.efector.id,sample_type:{...st}}))],
                                efector_id : action.study.efector_id,
                                efector : action.study.efector
                            }
                        ] : 
                        [   
                            {
                                id: 1,
                                study_type:{...studyTypeToAdd},
                                samples:[...studyTypeToAdd.sample_types.map(st=>({efector:action.study.efector, efector_id:action.study.efector.id,sample_type:{...st}}))],
                                efector_id : action.study.efector_id,
                                efector : action.study.efector
                            }
                        ]
                    }
                }            
            });

            return state
        case actionTypes.removeStudy:
            action.study.created_at ?
                state = [...state.map(study => study.study_type.code !== action.study.study_type.code ? study : {...study, mark_remove:true})]:
                state = [...state.filter(study => study.study_type.code !== action.study.study_type.code)]
            return state
        case actionTypes.editStudy:
            return [...state.map(study => study.study_type.code === action.study.study_type.code ? action.study : study)]
        case actionTypes.clearStudies:
            return [];
        default:
            return state
    }}

export default studies;
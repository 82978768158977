import { Delete, Remove, Visibility } from "@mui/icons-material";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMeasurementSampleType, addMeasurementSampleTypes, addMeasurementStudyType, clearMeasurementSampleTypes, clearMeasurementStudyTypes, createMeasurementType, fetchMeasurementSampleTypes, fetchMeasurementStudyTypes, fetchMeasurementTypes, removeMeasurementSampleType, removeMeasurementStudyType, setMeasurementType, updateMeasurementType} from './../state/handlers/measurementTypes';
import { selectMeasurementType, selectMeasurementTypes } from "../state/selectors/measurementTypes";
import { selectCatalogs, selectSamples, selectSamplesByCodes, selectStudies, selectStudiesByCodes } from "../state/selectors/catalogs";

import '../styles/Views.css';

const OptionsDialog = ({initOptions, open, close}) => {

    const [value, setValue] = useState('');
    const [options,setOptions] = useState(initOptions);

    const handleRemove = (value) =>{
        setOptions(options.filter(option => option !== value))
    }

    const handleAdd = (value) =>{
        setOptions([...options, value]);
    }
    
    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            handleAdd(value);
            setValue('');
        }
    }

    return (<Dialog
        open={open}
        fullWidth
        PaperProps={{ style: { padding: '3em' } }}>
        <DialogTitle id="station-dialog-title">Opciones</DialogTitle>
        <DialogContent>
            <div className="column-container">
                <TextField 
                    placeholder="Ingresar el texto y enter" 
                    onKeyDown={handleKeyPress}  
                    onChange={(event) => setValue(event.target.value)} 
                    value={value}
                    size="small"
                />
                <Paper>
                    <List>
                        {
                            options.map(value => 
                                <ListItem
                                    key={value}
                                    disableGuttersr
                                    secondaryAction={<IconButton onClick={()=>handleRemove(value)}><Delete /></IconButton>}>
                                    <ListItemText primary={value} />
                                </ListItem>)
                        }
                    </List>
                </Paper>
            </div>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" variant="contained" onClick={() => close(options)} >ACEPTAR</Button>
            <Button color="secondary" variant="contained" onClick={() => close(initOptions)}>CANCELAR</Button>
        </DialogActions>
    </Dialog>) 
}

const Studies = ({studies, catalog, add, remove}) => {
    
    const handleSelect = (event, values) => {
        if (values){       
            add(values.code);
        }    
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            sortable: false,
            hide:true,
            type: 'number'
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 35,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 1,
            minWidth: 150
        },
        {
            field: 'action_remove',
            headerName: 'Quitar',
            sortable: false,
            minWidth: 10,
            renderCell: (params) => <IconButton onClick={()=>remove(params.row.code)}><Delete/></IconButton>

        }
    ];

    const data = {rows:studies,columns:columns};

    return(<div className="column-container flex-1 margin-top">
        <Autocomplete id="study_search" name="study_search" size="small"
            loading={false}
            options={catalog || []}
            autoComplete
            includeInputInList
            getOptionLabel={(option) => `${option.code} ${option.name}`}
            onChange={(event, values) => handleSelect(event, values)}
            renderInput={(params) => (<TextField {...params} id="studyType" name="studyType" label="Buscar estudio" variant="outlined"/>)}
        />
        <div style={{height:'15em'}}>
            <DataGridPro
                {...data}
                headerHeight={60}
                rowHeight={40}
                disableMultipleSelection
                disableColumnMenu
                disableColumnSelector
                disableMultipleColumnsSorting
                hideFooter
                onCellClick={(params) => handleSelect(params.row)}
            />
        </div>
    </div>)
};

const Samples = ({samples, catalog, add, remove}) => {
    
    const handleSelect = (event, values) => {
        if (values){       
            add(values.code);
        }    
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            sortable: false,
            hide:true,
            type: 'number'
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 35,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 1,
            minWidth: 150
        },
        {
            field: 'action_remove',
            headerName: 'Quitar',
            sortable: false,
            minWidth: 10,
            renderCell: (params) => <IconButton onClick={()=>remove(params.row.code)}><Delete/></IconButton>

        }
    ];

    const data = {rows:samples,columns:columns};

    return(<div className="column-container flex-1 margin-top">
        <Autocomplete id="study_search" name="study_search" size="small"
            loading={false}
            options={catalog || []}
            autoComplete
            includeInputInList
            getOptionLabel={(option) => `${option.code} ${option.name}`}
            onChange={(event, values) => handleSelect(event, values)}
            renderInput={(params) => (<TextField {...params} id="studyType" name="studyType" label="Buscar estudio" variant="outlined"/>)}
        />
        <div style={{height:'15em'}}>
            <DataGridPro
                {...data}
                headerHeight={60}
                rowHeight={40}
                disableMultipleSelection
                disableColumnMenu
                disableColumnSelector
                disableMultipleColumnsSorting
                hideFooter
                onCellClick={(params) => handleSelect(params.row)}
            />
        </div>
    </div>)
};

const StudiesAndSamplesDialog = ({id, current, studiesCatalog, samplesCatalog, open, close}) => {

    const [tabIndex,setTabIndex] = useState(0);

    const dispatch = useDispatch();

    const studies = useSelector(selectStudiesByCodes(current.studyTypes || []));
    const samples = useSelector(selectSamplesByCodes(current.sampleTypes || []));

    useEffect(() => {
        dispatch(fetchMeasurementStudyTypes(id));
        dispatch(fetchMeasurementSampleTypes(id));

        return () => {
            dispatch(clearMeasurementStudyTypes());
            dispatch(clearMeasurementSampleTypes());
        }

    }, []);


    const handleAddStudy = (code) => {
        dispatch(addMeasurementStudyType(current.id,code));
    };

    const handleRemoveStudy = (code) => {
        dispatch(removeMeasurementStudyType(current.id,code));
    };

    const handleAddSample = (code) => {
        dispatch(addMeasurementSampleType(current.id,code));
    };

    const handleRemoveSample = (code) => {
        dispatch(removeMeasurementSampleType(current.id,code));
    };

    return (
        <Dialog
            open={open}
            fullWidth
            PaperProps={{ style: { padding: '1em' } }}>
          
            <DialogContent>
                <div className="column-container">
                    <Tabs 
                        value={tabIndex} 
                        onChange={(event, newValue)=>setTabIndex(newValue)} 
                        variant="fullWidth" 
                        style={{marginTop:'1em', backgroundColor:'#f4f4f4'}}
                    >
                        <Tab label="Estudios" sx={tabIndex == 0 ? {textTransform:'none', backgroundColor:'white'}:{textTransform:'none'}}/>
                        <Tab label="Muestras" sx={tabIndex == 1 ? {textTransform:'none', backgroundColor:'white'}: {textTransform:'none'}}/>
                    </Tabs>

                    <div className="grow">
                        {tabIndex===0 && <Studies studies={studies} catalog={studiesCatalog} add={handleAddStudy} remove={handleRemoveStudy}/>}
                        {tabIndex===1 && <Samples samples={samples} catalog={samplesCatalog} add={handleAddSample} remove={handleRemoveSample}/>}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={() => close()}>ACEPTAR</Button>
                <Button color="secondary" variant="contained" onClick={() => close()}>CANCELAR</Button>
            </DialogActions>
        </Dialog>)
}

const Measurement = () => {

    const dispatch = useDispatch();
    
    const [optionsDialog,openOptionsDialog] = useState(false);
    const [studiesSamplesDialog,openStudiesSamplesDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchMeasurementTypes())
    }, []);

    const {loading, current} = useSelector(selectMeasurementTypes);
    const catalogs = useSelector(selectCatalogs);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            sortable: false,
            type: 'number'
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 85,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 0.5,
            minWidth: 110
        },
        {
            field: 'type',
            headerName: 'Tipo',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            renderCell: (params) => params.row.layout.type

        }
    ];

    const data = {rows:catalogs.measurementTypes,columns:columns};

    const handleCloseOptionsDialog = (data) => {
        openOptionsDialog(false);
        dispatch(setMeasurementType({...current, layout:{...current.layout, options:data}}));
    }

    const handleCloseStudiesSamplesDialog = (data) => {
        openStudiesSamplesDialog(false);
    }

    const handleSelect = (measurement_type) => {
        dispatch(setMeasurementType(measurement_type));
    } 

    const handleSave = () => {

        if (current.id)
        {
            dispatch(updateMeasurementType(current));
        }
        else
        {
            dispatch(createMeasurementType(current));
        }
    }

    const handleChange = (event) => {
        
        if (event.target.name == "type")
        {
            dispatch(setMeasurementType({...current, layout:{...current.layout, type:event.target.value}}));
        }
        else if (event.target.name == "label")
        {
            dispatch(setMeasurementType({...current, layout:{...current.layout, label:event.target.value}}));
        }
        else
        {
            dispatch(setMeasurementType({...current, [event.target.name]:event.target.value}));
        }
    }

    return(
    <div className="column-container all-height">
        <div className="header row-container align-center">
            <div>
                <Typography variant="h5" color="text.black">
                    <Box fontWeight={750}>Mediciones y Requerimientos</Box>
                </Typography>
            </div>
            <div className="grow"></div>
            <div className="grow margin">
                <TextField 
                    placeholder="Buscar" 
                    variant="standard" 
                    fullWidth
                    size="small"
                />
            </div>
        </div>

        <div className="body grow row-container">
            <div className="flex-50 column-container padding">
                <div className="row-container half-margin ">
                    <Button 
                        variant="contained" 
                        style={{marginRight:'1em'}} 
                        color="secondary"
                        onClick={()=>dispatch(setMeasurementType({}))}
                    >Nuevo</Button>
                    <Button 
                        variant="contained" 
                        color="secondary"
                        onClick={()=>handleSave()}
                    >Guardar</Button>
                </div>
                <TextField 
                    id="code"
                    name="code"
                    className="half-margin" 
                    placeholder="Codigo" 
                    size="small"
                    value={current?.code || ''}
                    onChange={handleChange}
                />
                <TextField 
                    id="name"
                    name="name"
                    className="half-margin" 
                    placeholder="Nombre" 
                    size="small"
                    value={current?.name || ''}
                    onChange={handleChange}
                />
                <TextField 
                    id="type"
                    name="type"
                    className="half-margin" 
                    placeholder="Tipo"
                    select 
                    size="small"
                    value={current?.layout?.type || ''}
                    onChange={handleChange}
                >
                    <MenuItem key="text" value="text">Texto</MenuItem>
                    <MenuItem key="number" value="number">Numero</MenuItem>
                    <MenuItem key="list" value="list">Lista</MenuItem>
                    <MenuItem key="date" value="date">Fecha</MenuItem>
                </TextField>
                <TextField 
                    id="options"
                    className="half-margin" 
                    placeholder="Opciones" 
                    size="small"
                    value={current?.layout?.options ? current?.layout?.options?.join(',') : ''}
                    InputProps={{ endAdornment: <InputAdornment position="end"><IconButton onClick={()=>openOptionsDialog(true)}><Visibility /></IconButton></InputAdornment> }}
                />
                <TextField 
                    id="label"
                    name="label"
                    className="half-margin" 
                    placeholder="Etiqueta" 
                    size="small"
                    value={current?.layout?.label || ''}
                    onChange={handleChange}
                />
                
                <div className="column-container half-margin">
                    <Button 
                        variant="outlined"
                        onClick={()=>openStudiesSamplesDialog(true)}
                    >Estudios y muestras</Button>
                </div>
            </div>

            <div className="flex-50 padding">
                <DataGridPro
                    {...data}
                    headerHeight={60}
                    rowHeight={40}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    hideFooter
                    onCellClick={(params) => handleSelect(params.row)}
                />
            </div>
        </div>

        {
            optionsDialog && 
            <OptionsDialog initOptions={current?.layout?.options ? current?.layout?.options : []} open={optionsDialog} close={handleCloseOptionsDialog}/>
        }

        {
            studiesSamplesDialog &&
            <StudiesAndSamplesDialog id={current?.id} current={current} studiesCatalog={catalogs.studies} samplesCatalog={catalogs.sampleTypes} open={studiesSamplesDialog} close={handleCloseStudiesSamplesDialog}/>
        }

    </div>
    )
}

export default Measurement;
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import Patient from "../views/Patient";

const PatientModal = ({open, onClose, initPatient}) => {
    return(
        <Dialog 
            open={open}
        >
            <DialogTitle>Paciente</DialogTitle>
            <DialogContent>
                <Typography>Podes buscar el paciente y si no esta crearlo.</Typography>
                <Typography>Tambien podes editar pacientes existentes.</Typography>
                <Patient initPatient={initPatient} onPatientSelected={(patient)=>onClose(patient)}></Patient>
            </DialogContent>
        </Dialog>
    )
};

export default PatientModal;

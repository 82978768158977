import React, { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from '../state/selectors/profile';
import { useMsal } from '@azure/msal-react';
import AuthContext from '../context/AuthCotext';
import { logout } from '../state/handlers/profile';

const Profile = () => {

    const profile = useSelector(selectProfile);
    const dispatch = useDispatch();

    const {instance} = useMsal();
    const {authHandler} = useContext(AuthContext);

    const handleLogout = () => {
        if (authHandler==='PASSPORT'){
            dispatch(logout());
            return;
        }
        if (authHandler==='AZURE_AD'){
            instance.logoutRedirect();
            return;
        }
    };

    return (

        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Perfil de usuario</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em', padding: '3em' }}>
                    <div style={{ marginBottom: '1em' }}>
                        <Typography>{profile.data?.name}</Typography>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Typography>{profile.data?.email}</Typography>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Typography>{profile.data?.role?.description}</Typography>
                    </div>
                    <div style={{ marginBottom: '3em' }}>
                        <Button size="small" variant="contained" fullWidth onClick={handleLogout}>Log out</Button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Profile;
import React, { useEffect, useState } from "react";

import { Box, Button, IconButton, LinearProgress, TextField, Typography } from "@mui/material";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BatchCard from "../components/BatchCard";
import { format, parseISO } from 'date-fns';
import '../styles/Views.css';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Assessment,  Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {filterBatches} from "./../state/selectors/batches";
import {  fetchBatches, fetchCustomerBatches } from "../state/handlers/batches";
import { clearBatches } from "../state/actions/batches";
import { Link, useNavigate } from "react-router-dom";

import { createBatch } from "../state/handlers/batch";
import { selectBatch } from "../state/selectors/batch";
import { clearBatch } from "../state/actions/batch";
import { progress, translate } from "../helpers/batchStatus";
import { selectProfile } from "../state/selectors/profile";

const VIEW_TYPE_LIST = "LIST";
const VIEW_TYPE_CARDS = "CARDS";

const CardsView = ({batches}) => {
    return (
        <div className="row-container flex-wrap">
            {
                batches?.map(
                    batch=>
                    <div className="flex-50" key={batch?.id}>
                        <BatchCard batch={batch}/>
                    </div>)
    
            }            
        </div>
        
        
    )
}

const ListView = ({batches}) => {

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            minWidth: 50,
            sortable: false,
            type: 'string'
        },
        {
            field: 'customer_name',
            headerName: 'Cliente',
            minWidth: 250,
            sortable: false,
            type: 'string',
            valueGetter: (params) => params.row.customer?.name 
        },
        {
            field: 'date',
            headerName: 'Fecha',
            sortable: false,
            type: 'date',
            minWidth: 50,
            valueGetter: (params) => format(parseISO(params.value), 'dd/MM/yyyy')
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            minWidth: 120,
            valueGetter: (params) => translate(params.value)
        },
        {
            field: 'progress',
            headerName: 'Progreso',
            sortable: false,
            flex:1,
            renderCell: (params) => <LinearProgress color="primary" sx={{width:'100%', height:'1em'}} variant="determinate" value={progress(params.row.status)}></LinearProgress>,
            minWidth: 80
        },
        {
            field: 'view_batch',
            headerName: '',
            renderCell: (params) => <Link to={`/batch/${params.row.id}`} style={{textDecoration:'none'}}><Button color="secondary" startIcon={<Visibility />}>VER / EDITAR</Button></Link>,
            type: 'actions',
            minWidth: 180
        },
        {
            field: 'view_results',
            headerName: '',
            renderCell: (params) => <Link to={`/results?batch=${params.row.id}`} style={{textDecoration:'none'}}><Button  sx={{color:"black"}} startIcon={<Assessment/>}><Typography variant="body2"><Box fontWeight={550}>RESULTADOS</Box></Typography></Button></Link>,
            type: 'actions',
            minWidth: 180
        },

    ];

    const data = {rows:batches, columns:columns};

    return(
        <DataGridPro
            {...data}
            headerHeight={60}
            rowHeight={70}
            disableMultipleSelection
            disableColumnMenu
            disableColumnSelector
            disableMultipleColumnsSorting
            hideFooter
        />
    )
}

const Dashboard = () => {

const [viewType,setViewType] = useState(VIEW_TYPE_CARDS);
const [filter, setFilter] = useState('');

const {data, created, creating} = useSelector(selectBatch);
const profile = useSelector(selectProfile);

const isCustomer = profile.data?.role.code === 'customer';
    const customerId = profile.data?.customer?.id;

const navigate = useNavigate();
const dispatch = useDispatch();

useEffect(() => {
    
    if(isCustomer)
    {
        dispatch(fetchCustomerBatches(customerId))
    }else{
        dispatch(fetchBatches())
    }

    return () => {
        dispatch(clearBatches);
        dispatch(clearBatch);
    }
}, [profile]);


useEffect(() => {
    created && navigate(`/batch/${data.id}`);
}, [created]);

const handleNew = () => {
    dispatch(createBatch(profile.data.customer.id));
}

const batches = useSelector(filterBatches(filter));

return ( 
    <div className="column-container all-height">
        <div className="header row-container align-center">
            <div>
                <Typography variant="h5" color="text.black">
                    <Box fontWeight={750}>Mis Lotes</Box>
                </Typography>
            </div>
            <div className="grow"></div>
            <div className="grow margin">
                <TextField 
                    placeholder="Buscar" 
                    variant="standard" 
                    fullWidth
                    size="small"
                    value={filter}
                    onChange={(event)=>setFilter(event.target.value)}
                />
                
            </div>
            <div>
                <Button 
                    variant="contained"
                    color="secondary"
                    disabled={!profile.data.customer}
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                    onClick={()=>handleNew()}>
                        <Typography>
                            Nuevo lote
                        </Typography>
                </Button>
            </div>

            {
                creating && <LinearProgress/>
            }

        </div>
       
        <div className="column-container body" style={{height:'100%'}}>
            <div className="row-container justify-right">
                <IconButton onClick={()=>setViewType(VIEW_TYPE_LIST)}>
                    <FormatListBulletedIcon/>
                </IconButton>
                <IconButton onClick={()=>setViewType(VIEW_TYPE_CARDS)}>
                    <ViewModuleIcon/>
                </IconButton>
            </div>

            <div className="grow" style={{overflowY:'auto', maxHeight:'70vh'}}>

                {   
                    viewType === VIEW_TYPE_CARDS && 
                        <CardsView batches={batches}/>
                }

                {   
                    viewType === VIEW_TYPE_LIST && 
                        <ListView batches={batches}/>
                }

            </div>

        </div>
    </div>
    );
};

export default Dashboard;

import React from "react";
import { useDispatch } from "react-redux";
import { editMeasurement } from "../state/actions/measurement";
import { Box, Typography } from "@mui/material";
import MultiData from "../components/MultiData";

export const MeasurementCard = ({measurement}) => {

    const dispatch = useDispatch();

    const handleChange = (event) =>{
        dispatch(editMeasurement(measurement.id, event.target.value));
    }

    return(
        <div className="column-container" style={{marginRight:'1em'}}>
            <Box fontWeight="medium">
                <Typography>{measurement.measurement_type.name}</Typography>
            </Box>
            <MultiData {...measurement.measurement_type} value={measurement.value} handleChange={handleChange}/>
        </div>
    )
}
import { actionTypes } from '../../actions/sample';
import { actionTypes as orderActionTypes} from '../../actions/order';


const sampleRecord = {
    auto:false,
    locator:'',
    sampleType:{}
}

const sample = (state = sampleRecord, action) => {
    switch (action.type) {
        case orderActionTypes.clearOrder:
            return sampleRecord
        case actionTypes.setSampleLocator:
            return {...state, auto:false, locator:action.locator}
        case actionTypes.requestLastSampleLocator:
            return {...state, auto:true}
        case actionTypes.receiveLastSampleLocator:
            return {...state, locator:action.locator}
        case orderActionTypes.addStudy:
            const studyTypesToAdd = !action.study.study_type.isRoutine ? [action.study.study_type] : action.study.study_type.study_types;
            const sampleType = studyTypesToAdd[0].sample_types[0];
            return {...state, sampleType : sampleType}
        default:
            return state
    }
}

export default sample;


import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, MenuItem, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsersCatalog } from '../state/selectors/catalogs.users';
import { selectCustomers } from '../state/selectors/catalogs.customers';
import { createUser, updateUser } from '../state/handlers/catalogs.user';
import { selectRoles } from '../state/selectors/catalogs';

const UserDialog = ({open, close, refresh,  values}) => {

    const [user,setUser] = useState(values);
    const dispatch = useDispatch();

    const {saving, saved, current} = useSelector(selectUsersCatalog);
    const customers = useSelector(selectCustomers);

    const roles = useSelector(selectRoles);


    useEffect(() => {
        saved && refresh();
    }, [saved]);

    const handleChange = (event)=>{
        setUser({...user, [event.target.name]:event.target.value});
    }

    const handleSave = (user) => {
        !user.id ? dispatch(createUser(user)) : dispatch(updateUser(user));
    };

    const isDirty = current !== user;

    return <Dialog open={open}>
            <DialogTitle>Usuario</DialogTitle>
            <DialogContent style={{padding:'2em', minWidth:'20em'}}>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <TextField id="email" size="small" name="email" label="Email" value={user?.email || ''} onChange={handleChange}></TextField>
                    </Grid>
                    <Grid item>
                        <TextField id="name" size="small" name="name" label="Nombre" value={user?.name || ''} onChange={handleChange}></TextField>
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="customer_id" 
                            size="small" 
                            name="customer_id" 
                            label="Cliente" 
                            fullWidth
                            select 
                            value={user?.customer_id || ''} 
                            onChange={handleChange}>
                                {
                                    customers.map(customer => (
                                        <MenuItem key={customer.id} value={customer.id}>
                                            {customer.name}
                                        </MenuItem>
                                    ))
                                }
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField 
                            id="role_id" 
                            size="small" 
                            name="role_id" 
                            label="Rol" 
                            fullWidth
                            select 
                            value={user?.role_id || ''} 
                            onChange={handleChange}>
                                {
                                    roles.map(role => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.description}
                                        </MenuItem>
                                    ))
                                }
                        </TextField>
                    </Grid>
                    <Grid item>
                        {
                            saving && <LinearProgress/>
                        }
                    </Grid>

                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>close()}>Cerrar</Button>
                <Button 
                    variant="contained"
                    color="secondary"
                    disabled={!isDirty}
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                    onClick={()=>handleSave(user)}>
                        <Typography>
                            Guardar
                        </Typography>
                </Button>
            </DialogActions>
        </Dialog>}

export default UserDialog;
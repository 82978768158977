import React, { Fragment, useEffect, useState } from "react";
import { AddCircle, ArrowBack, ArrowForward,  ModeEdit,  Save } from "@mui/icons-material";
import { Autocomplete, Button,    LinearProgress, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { createPatient, updatePatient } from "../state/handlers/patient";
import { fetchPatients } from "../state/handlers/patients";
import { selectCurrentOrder } from "../state/selectors/currentOrder";
import { selectPatients } from "../state/selectors/patients";
import { selectProfile } from "../state/selectors/profile";

import '../styles/Views.css';
import { genders } from "../helpers/genders";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { clearPatient } from "../state/actions/patient";
import { addYears, differenceInBusinessDays, differenceInYears, format, formatISO, parse, parseISO } from "date-fns";

const Patient = ({initPatient, onPatientSelected}) => {

    const dispatch = useDispatch();
    
    const [patient,setPatient] = useState(initPatient);
    const [search,setSearch] = useState('');
    const [openSearch, setOpenSearch] = useState(false);

    const {data,searching} = useSelector(selectPatients);
    const {loading} = useSelector(selectCurrentOrder);
    const profile = useSelector(selectProfile);

    const [newMode, setNewMode] = useState(false);
    const [selectedMode, setSelectedMode] = useState(initPatient?.id || false);

    const patients = data;

    useEffect(() => {
        setPatient(initPatient);
    }, [initPatient]);

    const handleSelectPatient = (event, values) => {

        if (values){       
            setPatient({...values});
            setSearch('');
            setSelectedMode(true);
        }    
    };

    const handleNew = () => {
        setNewMode(true);
        setPatient({});
    };

    const handleCancel = () => {
        setNewMode(false);
        setSelectedMode(false);
    };

    const handleSaveRequest = () => {
        setNewMode(false);
        setSelectedMode(true);
    }

    const handleChangeSearchPatient = (event) => {
        event?.type === 'change' && setSearch(event.target.value);
    };

    const handleKeyPressPatientSearch = (event) => {
        if (event.key === 'Enter') {
            dispatch(fetchPatients(profile.data.customer.id,event.target.value));
        }
    };

    
    return (

        <div style={{width:'30em'}} className="column-container">

            <div className="row-container" style={{marginTop:'1em',marginBottom:'1em'}}>
                <Autocomplete
                    id="patient_search"
                    name="patient_search"
                    open={openSearch}
                    selectOnFocus
                    fullWidth
                    size="medium"
                    disabled={newMode||selectedMode}
                    onOpen={() => {setOpenSearch(true)}}
                    onClose={() => {setOpenSearch(false)}}
                    onChange={(event, values) => handleSelectPatient(event, values)}
                    onInputChange={(event) => handleChangeSearchPatient(event)}
                    inputValue={search}
                    options={patients || []}
                    disableClearable
                    loading={false}
                    value={patient || null}
                    getOptionLabel={(option) => `${option.number} ${option.last_name} ${option.first_name}`}
                    isOptionEqualToValue={(option,value)=> option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="patient"
                            name="patient"
                            label="Ingresa algun dato del paciente y ENTER"
                            variant="outlined"
                            onKeyUp={(event) => handleKeyPressPatientSearch(event)}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Fragment>
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                ),
                            }}
                        />
                    )}
                />

                <Button 
                    color="primary"
                    variant="outlined"
                    endIcon={<AddCircle/>}
                    onClick={handleNew}
                    disabled={newMode||selectedMode}
                    sx={{height:'4em', width:'10em', textTransform:'none', marginLeft:'1em'}}>
                        <Box fontWeight="medium">
                            <Typography>Nuevo</Typography>
                        </Box>
                </Button>
            </div>

            {
                (newMode || selectedMode) && <PatientForm patient={patient} newMode={newMode} onCancel={handleCancel} onSaveRequest={handleSaveRequest}/>
            }

            <div className="half-margin">{(loading || searching) && <LinearProgress color="primary"/>}</div>

            <div className="row-container" style={{marginTop:'1em'}}>
                <Button 
                    color="primary"
                    startIcon={<ArrowBack color="secondary" />}
                    onClick={()=>onPatientSelected(undefined)}
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'1em', paddingRight:'1em',  width:'10em', marginBottom:'1em'}}>
                        <Box fontWeight="medium">
                            <Typography>Volver</Typography>
                        </Box>
                </Button>
                
                <div className="grow"></div>
                
                <Button 
                    color="primary"
                    endIcon={<ArrowForward color="secondary" />}
                    onClick={()=>onPatientSelected(patient)}
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'1em', paddingRight:'1em',  width:'10em', marginBottom:'1em'}}>
                        <Box fontWeight="medium">
                            <Typography>Continuar</Typography>
                        </Box>
                </Button>
            </div>
        </div>
    )
}

export default Patient;


const PatientForm = ({patient, newMode, onCancel, onSaveRequest}) => {

    const dispatch = useDispatch();
    
    const [editMode, setEditMode] = useState(false);
    const profile = useSelector(selectProfile);
    const {status}= useSelector(selectCurrentOrder);

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = (values) => {


        if(newMode){
            dispatch(createPatient(profile.data.customer.id,values))
        }
        else if(editMode){
            dispatch(updatePatient(profile.data.customer.id,values));
            setEditMode(false);
        }

        onSaveRequest();
    }

 
    return(

        <Paper style={{padding:'2em'}}>

            <Formik
                initialValues={patient}
                initialErrors={undefined}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    number: Yup.string().required('requerido'),
                    last_name: Yup.string().required('requerido'),
                    first_name: Yup.string().required('requerido'),
                    birth_day : Yup.date().min('17530101','Mínimo 01/01/1753').required('requerido'),
                    age:Yup.number().required('requerido'),
                    gender: Yup.string().required('requerido')
                })}

                onSubmit={(values,{setSubmitting}) => {
                    handleSave(values);
                }}

                onReset={(values) => {
                }}
            >
            
            {(props) => {
                
                const {values,touched,errors,dirty,isSubmitting,isValid,handleChange,handleBlur,handleSubmit,handleReset} = props;

                const canSave = (editMode || newMode)  && isValid && dirty && !status.loadingPatient;

                const handleChangeAge = (value) => {
                    const today = new Date();
                    const birthDay = format(addYears(today,-value),"yyyy-MM-dd");
                    handleChange({target:{name:'birth_day', value:birthDay}});
                    handleChange({target:{name:'age', value:value}});
                }

                const handleChangeBirthDay = (value) => {
                    const birthDay = parseISO(value);
                    const age = differenceInYears(new Date(),birthDay)
                    handleChange({target:{name:'birth_day', value:value}});
                    handleChange({target:{name:'age', value:age}});
                }

                const handleCancel = () => {
                    handleReset();
                    editMode && setEditMode(false);
                    !editMode && onCancel();
                }

                return (

                    <Form onSubmit={handleSubmit} onReset={handleCancel}>
                        <div className="half-margin">
                            <TextField
                                id="number"
                                name="number"
                                placeholder="Identificacion"
                                label="Identificacion"
                                variant="standard"
                                disabled={!newMode && !editMode}
                                value={values?.number}
                                helperText={touched?.number && errors?.number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>

                        <div className="half-margin">
                            <TextField
                                id="last_name"
                                name="last_name"
                                label="Apellido"
                                placeholder="Apellido"
                                variant="standard"
                                fullWidth
                                disabled={!newMode && !editMode}
                                value={values?.last_name || ''}
                                helperText={touched?.last_name && errors?.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>

                        <div className="half-margin">
                            <TextField
                                id="first_name"
                                name="first_name"
                                label="Nombre"
                                placeholder="Nombre"
                                variant="standard"
                                fullWidth
                                disabled={!newMode && !editMode}
                                value={values?.first_name}
                                helperText={touched?.first_name && errors?.first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>

                        <div className="row-container half-margin">
                            <div style={{flex:1,marginRight:'0.5em'}}>
                                <TextField
                                    id="birth_day"
                                    name="birth_day"
                                    label="Fecha de nacimiento"
                                    variant="standard"
                                    type="date"
                                    disabled={!newMode && !editMode}
                                    value={values?.birth_day || ''}
                                    helperText={touched?.birth_day && errors?.birth_day}
                                    onChange={(event)=>handleChangeBirthDay(event.target.value)}
                                    onBlur={handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div style={{flex:1,marginRight:'0.5em'}}>
                                <TextField
                                    id="age"
                                    name="age"
                                    label="Edad en años"
                                    variant="standard"
                                    disabled={!newMode && !editMode}
                                    value={values?.age || ''}
                                    helperText={touched?.age && errors?.age}
                                    onChange={(event)=>handleChangeAge(event.target.value)}
                                    onBlur={handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div style={{flex:2, marginLeft:'0.5em'}}>
                                <TextField
                                    id="gender"
                                    name="gender"
                                    label="Sexo"
                                    variant="standard"
                                    select
                                    fullWidth
                                    disabled={!newMode && !editMode}
                                    value={values?.gender || ''}
                                    helperText={touched?.gender && errors?.gender}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                {
                                    genders.map(g => (
                                        <MenuItem key={g.gender} value={g.gender}>
                                            {g.description}
                                        </MenuItem>
                                    ))
                                }
                                </TextField>
                            </div>
                        </div>

                        <div className="row-container justify-space-between" style={{marginTop:'2em'}}>
                            
                            <Button 
                                color="primary"
                                variant="outlined"
                                endIcon={<ModeEdit/>}
                                disabled={(newMode || editMode)}
                                onClick={()=>handleEdit()}
                                sx={{height:'2.5em', textTransform:'none', marginRight:'1em'}}>
                                    <Box fontWeight="medium">
                                        <Typography>Editar</Typography>
                                    </Box>
                            </Button>

                            <Button 
                                color="primary"
                                variant="outlined"
                                type="reset"
                                sx={{height:'2.5em', textTransform:'none'}}>
                                    <Box fontWeight="medium">
                                        <Typography>Cancelar</Typography>
                                    </Box>
                            </Button>

                            <div className="grow"></div>

                            <Button 
                                variant="contained"
                                color="secondary"
                                endIcon={<Save/>}
                                type="submit"
                                disabled={!canSave}
                                sx={{height:'2.5em', textTransform:'none'}}
                            >
                                    <Box fontWeight="medium">
                                        <Typography>Guardar</Typography>
                                    </Box>
                            </Button>
                        </div>

                    </Form>
                )
            }}
            </Formik>
        </Paper>
)};
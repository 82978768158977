import { combineReducers } from 'redux';
import { actionTypes } from '../actions/explorer';


const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchExplorerSamples:
            return true
        case actionTypes.receiveExplorerSamples:
            return false
        case actionTypes.clearExplorerSamples:
            return false
        default:
            return state
    }
}

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receiveExplorerSamples:
            return action.samples
        case actionTypes.fetchExplorerSamples:
            return []
        case actionTypes.clearExplorerSamples:
            return []
        default:
            return state
    }
}

export default combineReducers({ loading, list });


import { actionTypes } from '../actions/patients';
import HttpService from "../../services/HttpService";

const receivePatients = (patients) => ({ type: actionTypes.receivePatients, patients });

export const fetchPatients = (customer, search) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchPatients });
        
        return HttpService.getInstance().getData(`customers/${customer}/patients?search=${search}`, 'user-token')
            .then(response => {
                dispatch(receivePatients(response.data));
        })
    }
}
import { combineReducers } from 'redux';
import { actionTypes } from '../actions/sampleRanges';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchSampleRanges:
            return true
        case actionTypes.receiveSampleRanges:
            return false
        case actionTypes.receiveSampleRange:
            return false
        default:
            return state
    }
}

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchSampleRanges:
            return []
        case actionTypes.receiveSampleRanges:
            return action.sample_ranges
        case actionTypes.receiveSampleRange:
            return [...state.map(sr => sr.id == action.sample_range.id ? action.sample_range : sr) ]
        case actionTypes.clearSampleRanges:
            return []
        default:
            return state
    }
}
export default combineReducers({ loading, list });


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectProfile } from "../state/selectors/profile";
import { user } from "../state/handlers/profile";
import Cookies from 'js-cookie'

const ProtectedRoute = ({ Component, scopes }) => {

    const profile = useSelector(selectProfile);
    //const dispatch = useDispatch();

    const token = profile?.data?.access_token || Cookies.get('token');

    /*
    useEffect(() => {
        if (token && !profile?.data?.id) {
            dispatch(user());
        }
    }, [token]);
    */
   
    const authorized = token && profile?.data?.id && (scopes.includes("any") || scopes.includes(profile?.data?.role?.code))
    return authorized ? <Component /> : <Navigate to="/" />
}

export default ProtectedRoute;
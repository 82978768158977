import { combineReducers } from 'redux';
import { actionTypes } from '../actions/batch';
import { actionTypes as orderActionTypes } from '../actions/order';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchBatch:
            return true
        case actionTypes.receiveBatch:
            return false
        default:
            return state
    }
}

const creating = (state = false, action) => {
    switch (action.type) {
        case actionTypes.creatingBatch:
            return true
        case actionTypes.receiveBatch:
            return false
        default:
            return state
    }
}

const created = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchBatch:
            return false
        case actionTypes.clearBatch:
            return false
        case actionTypes.createdBatch:
            return true
        default:
            return state
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.receiveBatch:
            return action.batch
        case actionTypes.clearBatch:
            return {}
        case orderActionTypes.removeOrder:
            return {...state, orders:[...state.orders.filter(o=>o.id !== action.order.id)]}
        default:
            return state
    }
}

export default combineReducers({ loading, data,creating,created });
import { combineReducers } from "redux";
import loading from "./loading";
import status from "./status";
import measurements from "./measurements";
import order from "./order";
import patient from "./patient";
import samples from "./samples";
import studies from "./studies";
import sample from "./sample";

export default combineReducers ({status, loading, order, patient, studies, samples, measurements, sample});
import { actionTypes } from '../actions/batch';
import HttpService from "../../services/HttpService";

const receiveBatch = (batch) => ({ type: actionTypes.receiveBatch, batch });

export const fetchBatch = (id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchBatch });

        return HttpService.getInstance().getData(`resource/batches/${id}`, 'user-token')
            .then(response => {
                dispatch(receiveBatch(response.data));
            })
    }
}

export const fetchCustomerBatch = (customerId,id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchBatch });

        return HttpService.getInstance().getData(`customers/${customerId}/batch/${id}`, 'user-token')
            .then(response => {
                dispatch(receiveBatch(response.data));
            })
    }
}

export const createBatch = (customerId) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.clearBatch });
        dispatch({ type: actionTypes.creatingBatch });

        return HttpService.getInstance().postData({},`customers/${customerId}/batch`, 'user-token')
            .then(response => {
                dispatch(receiveBatch(response.data));
                dispatch({ type: actionTypes.createdBatch });
            })
    }
}

export const updateBatch = (batch) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.clearBatch });
        dispatch({ type: actionTypes.creatingBatch });

        return HttpService.getInstance().putData({status:batch.status},`resource/batches/${batch.id}`, 'user-token')
            .then(response => {
                dispatch(receiveBatch(response.data));
                dispatch({ type: actionTypes.createdBatch });
            })
    }
}

export const updateCustomerBatch = (customerId,batch) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.clearBatch });
        dispatch({ type: actionTypes.creatingBatch });

        return HttpService.getInstance().putData(batch,`customers/${customerId}/batches/${batch.id}`, 'user-token')
            .then(response => {
                dispatch(receiveBatch(response.data));
                dispatch({ type: actionTypes.createdBatch });
            })
    }
}

export const changeCustomerBatchStatus = (customerId,batchId, status) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.clearBatch });
        dispatch({ type: actionTypes.creatingBatch });

        return HttpService.getInstance().putData({status:status},`customers/${customerId}/batches/${batchId}/status`, 'user-token')
            .then(response => {
                dispatch(receiveBatch(response.data));
                dispatch({ type: actionTypes.createdBatch });
            })
    }
}

export const changeBatchStatus = (batchId, status) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.clearBatch });
        dispatch({ type: actionTypes.creatingBatch });

        return HttpService.getInstance().putData({status:status},`batches/${batchId}/status`, 'user-token')
            .then(response => {
                dispatch(receiveBatch(response.data));
                dispatch({ type: actionTypes.createdBatch });
            })
    }
}
import { combineReducers } from 'redux';
import { actionTypes } from '../actions/catalogs.user';

const current = (state = {},action) => {
    switch (action.type) {
        case actionTypes.clearUser:
            return {}
        case actionTypes.receiveUser:
            return action.user
        case actionTypes.setUser:
            return action.user
        default:
            return state
    }
};

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchUsers:
            return []
        case actionTypes.receiveUsers:
            return action.users
        default:
            return state
    }
}

const loading = (state=false, action) => {
    switch (action.type) {
        case actionTypes.fetchUsers:
            return true
        case actionTypes.receiveUsers:
            return false
        default:
            return state
    }
}

const saving = (state=false, action) => {
    switch (action.type) {
        case actionTypes.saveUser:
            return true
        case actionTypes.receiveUser:
            return false
        case actionTypes.clearUser:
            return false
        case actionTypes.setUser:
            return false
        default:
            return state
    }
}

const saved = (state=false, action) => {
    switch (action.type) {
        case actionTypes.receiveUser:
            return true
        case actionTypes.clearUser:
            return false
        case actionTypes.setUser:
            return false
        default:
        return state
    }
}



export default combineReducers({ loading, saving, current, list,saved });


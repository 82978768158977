import { combineReducers } from 'redux';
import { actionTypes } from '../actions/catalogs';
import customers from './catalogs.customers';
import users from './catalogs.users';
import routines from './catalogs.routines';


const statusTemplate = {
    catalogs:null,
    studies:null,
    loadingTechicalNote:false
}

const status = (state = statusTemplate, action) => {
    switch (action.type) {
        case actionTypes.fetchCatalogs:
            return {...statusTemplate, catalogs:'fetch'}
        case actionTypes.fetchStudies:
            return {...statusTemplate, studies:'fetch'}
        case actionTypes.receiveStudies:
            return {...statusTemplate, studies:'ready', catalogs:'ready'}
        case actionTypes.fetchTechnicalNote:
            return {...state.status, loadingTechicalNote:true}
        case actionTypes.receiveTechnicalNote:
            return {...state.status, loadingTechicalNote:false}
        default:
            return state
    }
}

const studies = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchStudies:
            return []
        case actionTypes.receiveStudies:
            return action.studies
        case actionTypes.receiveTechnicalNote:
            return state.map(study=>{
                if (study.code === action.code){
                    if (action.technicalNote){
                        return {...study, technicalNote:action.technicalNote};
                    }
                }
                return study;
            })
        case actionTypes.addFavorite:
            return state.map(study => study.code === action.code ? {...study, isFavorite:true} : study)
        case actionTypes.removeFavorite:
            return state.map(study => study.code === action.code ? {...study, isFavorite:false} : study)
        default:
            return state
    }
}

const measurementTypes = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchMeasurementTypes:
            return []
        case actionTypes.receiveMeasurementTypes:
            return action.measurement_types
        case actionTypes.receiveMeasurementType:
            return [...state.filter(mt=>mt.id !== action.measurement_type.id), action.measurement_type]
        default:
            return state
    }
}

const sampleTypes = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchSampleTypes:
            return []
        case actionTypes.receiveSampleTypes:
            return action.samples
        default:
            return state
    }
}

const efectors = (state=[], action) => {
    switch (action.type) {
        case actionTypes.fetchEfectors:
            return []
        case actionTypes.receiveEfectors:
            return action.efectors
        default:
            return state
    }
}

const roles = (state=[], action) => {
    switch (action.type) {
        case actionTypes.fetchRoles:
            return []
        case actionTypes.receiveRoles:
            return action.roles
        default:
            return state
    }
}

export default combineReducers({ status, studies,measurementTypes,routines,sampleTypes, customers, users,efectors,roles});


export const selectBatch = (state) => {
    return state.batch;
}

export const selectBatchData = (state) => {
    return state.batch.data;
}

export const isLoadingBatch = (state) => {
    return state.batch.loading;
}
export const actionTypes = {
    fetchPatient: 'FETCH_PATIENT',
    receivePatient: 'RECEIVE_PATIENT',
    createPatient:'CREATE_PATIENT',
    updatePatient:'UPDATE_PATIENT',
    clearPatient : 'CLEAR_PATIENT',
    setPatient:'SET_PATIENT'
};

export const setPatient = (patient) => ({type: actionTypes.setPatient, patient});
export const clearPatient = () =>  ({type: actionTypes.clearPatient});


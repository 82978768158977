import { actionTypes, receiveLastSampleLocator, receiveSampleLocators, receiveSampleVerification, receiveSamples, verifySample } from '../actions/sample';
import HttpService from "../../services/HttpService";
import { receiveSimpleOrder } from './order';

export const sampleLocators = (customer, samples) => {
    return (dispatch) => {

        const body = {samples:samples};

        dispatch({ type: actionTypes.updateSamples });

        return HttpService.getInstance().postData(body,`customers/${customer}/sample_locators`, 'user-token')
            .then(response => {
                dispatch(receiveSampleLocators(response.data));
        })
    }
}

export const lastSampleLocator = (customer) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.requestLastSampleLocator });

        return HttpService.getInstance().getData(`customers/${customer}/last_sample_locator`, 'user-token')
            .then(response => {
                dispatch(receiveLastSampleLocator(response.data));
        })
    }
}

export const updateCustomerSamples = (customer,order, samples) => {
    return (dispatch) => {

        const body = {samples:samples};
        dispatch({ type: actionTypes.updateSamples });

        return HttpService.getInstance().putData(body,`customers/${customer}/orders/${order}/samples`, 'user-token')
            .then(response => {
                dispatch(receiveSimpleOrder(response.data.order));
                dispatch(receiveSamples(response.data.samples));
        })
    }
}

export const updateSamples = (order, samples) => {
    return (dispatch) => {

        const body = {samples:samples};
        dispatch({ type: actionTypes.updateSamples });

        return HttpService.getInstance().putData(body,`orders/${order}/samples`, 'user-token')

            .then(response => {
                dispatch(receiveSimpleOrder(response.data.order));
                dispatch(receiveSamples(response.data.samples));
        })
    }
}

export const clearSampleLocator = (sample) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateSamples });
        return HttpService.getInstance().putData({},`samples/${sample}/clear`, 'user-token')

            .then(response => {
                dispatch(receiveSimpleOrder(response.data.order));
                dispatch(receiveSamples(response.data.samples));
        })
    }
}

export const customerClearSampleLocator = (customer, sample) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateSamples });
        return HttpService.getInstance().putData({},`customers/${customer}/samples/${sample}/clear`, 'user-token')
            .then(response => {
                dispatch(receiveSimpleOrder(response.data.order));
                dispatch(receiveSamples(response.data.samples));
        })
    }
}

export const removeSample = (sample) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.removeSample });
        return HttpService.getInstance().deleteData(`samples/${sample}`, 'user-token')

            .then(response => {
                dispatch(receiveSimpleOrder(response.data.order));
                dispatch(receiveSamples(response.data.samples));
        })
    }
}

export const customerRemoveSample = (customer, sample) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.removeSample });
        return HttpService.getInstance().deleteData(`customers/${customer}/samples/${sample}`, 'user-token')
            .then(response => {
                dispatch(receiveSimpleOrder(response.data.order));
                dispatch(receiveSamples(response.data.samples));
        })
    }
}

export const verifyCustomerSampleLocator = (customer, sample) => {
    return (dispatch) => {

        dispatch(verifySample(sample));

        return HttpService.getInstance().getData(`customers/${customer}/samples/locator/${sample}`, 'user-token')
            .then(response => {
                dispatch(receiveSampleVerification(response.data.verified, sample));
        })
    }
}

export const verifySampleLocator = (customer, sample) => {
    return (dispatch) => {

        dispatch(verifySample(sample));
        return HttpService.getInstance().getData(`verify/customers/${customer}/samples/locator/${sample}`, 'user-token')
            .then(response => {
                dispatch(receiveSampleVerification(response.data.verified, sample));
        })
    }
}
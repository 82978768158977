import { combineReducers } from 'redux';
import { actionTypes } from '../actions/measurementTypes';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchMeasurementTypes:
            return true
        case actionTypes.createMeasurementType:
            return true
        case actionTypes.updateMeasurementType:
            return true
        case actionTypes.receiveMeasurementType:
            return false
        case actionTypes.receiveMeasurementTypes:
            return false
        default:
            return state
    }
}

const current = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.fetchMeasurementTypes:
            return {}
        case actionTypes.setMeasurementType:
            return action.measurement_type
        case actionTypes.receiveMeasurementType:
            return action.measurement_type
        case actionTypes.receiveMeasurementSampleTypes:
            return {...state, sampleTypes:[...action.codes]}
        case actionTypes.receiveMeasurementStudyTypes:
            return {...state, studyTypes:[...action.codes]}
        default:
            return state
    }
}


export default combineReducers({ loading, current });
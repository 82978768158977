import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { selectOrdersToSend } from '../state/selectors/ordersToSend';
import { clearOrdersToSend, sendNextOrder } from '../state/handlers/ordersToSend';

const SendToLisDialog = ({open, close}) => {

    const dispatch = useDispatch();
    const {status, list} = useSelector(selectOrdersToSend);

    useEffect(() => {

        if (status.status==='waiting'){
            dispatch(sendNextOrder());
        }

        return () => {
            dispatch(clearOrdersToSend());
        }
        
    }, []);

    useEffect(() => {
        if (status.status=='waiting'){
            if(status.waiting){
                dispatch(sendNextOrder());
            }
        }
    }, [status.waiting]);


    const handleClose = () => {
        
        
        if(status.sended > 0 && status.error === 0){
            close(true);
        }
        else{
            close(false);
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number',
            hide:true,
        },
        {
            field: 'number',
            headerName: 'Numero',
            sortable: false,
            type: 'string',
            flex: 1,
            width: 120,
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            flex: 1,
            minWidth: 110
        },
        {
            field: 'error',
            headerName: 'Error',
            sortable: false,
            flex: 1,
            minWidth: 110
        }
   ];

    const data = {rows:list || [], columns:columns}

    return <Dialog open={open} maxWidth="xl">
            <DialogTitle>Enviando a LIS</DialogTitle>
            <DialogContent style={{ padding:'2em', minWidth:'40em',maxWidth:'40em'}}>
                <Grid container direction={"column"} spacing={1}>
                        <Grid item container direction="row" spacing={1}>
                            <Grid item xs>
                                <TextField size="small" fullWidth label="Estado" value={status?.status || ''}/>
                            </Grid>
                            <Grid item xs>
                                <TextField size="small" fullWidth label="Eperando" value={status?.waiting || ''}/>
                            </Grid>
                            <Grid item xs>
                                <TextField size="small" fullWidth label="Enviadas" value={status?.sended || ''}/>
                            </Grid>
                            <Grid item xs>
                                <TextField size="small" fullWidth label="Error" value={status?.error || ''}/>
                            </Grid>
                        </Grid>
                        <Grid item>
                            { status.waiting > 0 && <LinearProgress/>}
                        </Grid>
                    <Grid item xs style={{minHeight:'15em'}}>
                        <DataGridPro
                            {...data}
                            headerHeight={40}
                            rowHeight={40}
                            disableMultipleSelection
                            disableColumnMenu
                            disableColumnSelector
                            disableMultipleColumnsSorting
                            hideFooter
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={status.waiting} onClick={()=>handleClose()}>Cerrar</Button>
            </DialogActions>
        </Dialog>}

export default SendToLisDialog;
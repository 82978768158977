export const actionTypes = {
    fetchRoutines:'FETCH_ROUTINES',
    receiveRoutines:'RECEIVE_ROUTINES',
    createRoutine:'CREATE_ROUTINE',
    updateRoutine:'UPDATE_ROUTINE',
    receiveRoutine:'RECEIVE_ROUTINE',
    setRoutine:'SET_ROUTINE',
    receiveRoutineStudies:'RECEIVE_ROUTINE_STUDIES',
    removeRoutineStudy:'REMOVE_ROUTINE_STUDY',
    addRoutineStudy:'ADD_ROUTINE_STUDY'
 };


import React, { useEffect, useState } from 'react';
import { Autocomplete, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, Folder } from '@mui/icons-material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { selectCustomersCatalog } from '../state/selectors/catalogs.customers';
import { createCustomer, updateCustomer } from '../state/handlers/catalogs.customer';

const CustomerDialog = ({open, close, refresh,  values}) => {

    const [customer,setCustomer] = useState(values);
    const dispatch = useDispatch();

    const {saving, saved, current} = useSelector(selectCustomersCatalog);

    useEffect(() => {
        saved && refresh();
    }, [saved]);

    const handleChange = (event)=>{
        setCustomer({...customer, [event.target.name]:event.target.value});
    }

    const handleSave = (customer) => {
        !customer.id ? dispatch(createCustomer(customer)) : dispatch(updateCustomer(customer));
    };

    const isDirty = current !== customer;

    const users_columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number',
            hide:true,
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: false,
            type: 'string',
            flex: 1,
            width: 120,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 1,
            minWidth: 110
        }
    ];

    const users = {rows:values?.users || [], columns:users_columns}

    return <Dialog open={open} maxWidth="xl">
            <DialogTitle>Cliente</DialogTitle>
            <DialogContent style={{ padding:'2em', minWidth:'40em'}}>
                
                <Grid container direction={"column"} spacing={1}>

                    <Grid item xs container direction='column' spacing={1}>
                        <Grid item>
                            <TextField id="code" size="small" fullWidth name="code" label="Codigo" value={customer.code || ''} onChange={handleChange}></TextField>
                        </Grid>
                        <Grid item>
                            <TextField id="name" size="small" fullWidth name="name" label="Nombre" value={customer.name || ''} onChange={handleChange}></TextField>
                        </Grid>
                        <Grid item>
                            <TextField id="lis_id" size="small" fullWidth name="lis_id" label="LIS Id" value={customer.lis_id || ''} onChange={handleChange}></TextField>
                        </Grid>
                        <Grid item>
                            <TextField id="lis_physician_id" size="small" fullWidth name="lis_physician_id" label="LIS Medico Id" value={customer.lis_physician_id || ''} onChange={handleChange}></TextField>
                        </Grid>
                        <Grid item>
                            {
                                saving && <LinearProgress/>
                            }
                        </Grid>
                    </Grid>

                        <Grid item xs={7} container direction="column">
                            <Grid item xs style={{minHeight:'15em'}}>
                                <DataGridPro
                                    {...users}
                                    headerHeight={40}
                                    rowHeight={40}
                                    disableMultipleSelection
                                    disableColumnMenu
                                    disableColumnSelector
                                    disableMultipleColumnsSorting
                                    hideFooter
                                />
                            </Grid>
                        </Grid>
                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>close()}>Cerrar</Button>
                <Button 
                    variant="contained"
                    color="secondary"
                    disabled={!isDirty}
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                    onClick={()=>handleSave(customer)}>
                        <Typography>
                            Guardar
                        </Typography>
                </Button>
            </DialogActions>
        </Dialog>}

export default CustomerDialog;
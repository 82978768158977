import React from "react"
import { Box, Typography } from "@mui/material"
import { format, parseISO } from "date-fns"
import { translate } from "../../helpers/genders"
import '../../styles/Views.css';

const PatientData = ({patient}) => {

return(
    <div className="column-container">
        <Box fontWeight="medium">
            <Typography variant="h6" color="text.black">{`${patient.first_name} ${patient.last_name}`}</Typography>
        </Box>
        <Typography color="text.gray" variant="body2">{`Identificacion ${patient.number}`}</Typography>
        <Typography color="text.gray" variant="body2">{`Fecha Nac. ${format(parseISO(patient.birth_day), 'dd/MM/yyyy')}`}</Typography>
        <Typography color="text.gray" variant="body2">{`Edad ${patient.age}`}</Typography>
        <Typography color="text.gray" variant="body2">{translate(patient.gender)}</Typography>
    </div>)}

export default PatientData;


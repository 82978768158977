import React from 'react';
import { AppBar, Avatar, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import '../styles/Components.css';
import { Link } from 'react-router-dom';
import { getInitials } from '../helpers/nameInitials';
import { useSelector } from 'react-redux';
import { selectProfile } from '../state/selectors/profile';
import { selectLogo } from '../ui/LogoSelector';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBarRight: {
        float: 'flex',
    },
    appBar: {
        zIndex: 1100,
        position: 'absolute',
    },
    hide: {
        display: 'none',
    },
}));

export default function TopBar() {

    const classes = useStyles();
    const theme = useTheme();
    const profile = useSelector(selectProfile);
    const initials = getInitials(profile.data?.name);

    const logo = selectLogo();

    return (
        <AppBar position="fixed" color="secondary">
            <Toolbar>
                <img className='img-topbar' src={logo}></img>
                <div className={classes.grow} />
                <div className={classes.appBarRight}>
                    <Typography>{profile.data?.name}</Typography>
                </div>
                <div className={classes.appBarRight}>
                    <Link style={{ textDecoration: 'none' }} to={profile.data?.loginSuccessful ? "profile" : ""}>
                        <IconButton
                            edge="end"
                            color="secondary"
                        >
                            <Avatar style={{ backgroundColor: theme.palette.background.header, color: theme.palette.secondary.main }}>{profile.data?.loginSuccessful && initials}</Avatar>
                        </IconButton>
                    </Link>
                </div>
            </Toolbar>
        </AppBar>
    );
}

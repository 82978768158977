import { actionTypes } from '../actions/ordersToSend';
import HttpService from "../../services/HttpService";

export const setOrdersToSend = (orders) => ({ type: actionTypes.setOrdersToSend, orders });
export const clearOrdersToSend = () => ({ type: actionTypes.clearOrdersToSend });

export const sendNextOrder = () => {
    return (dispatch,getState) => {

        const ordersToSend = getState().ordersToSend;

        if(ordersToSend.status.status !== 'sended'){
            if(ordersToSend.list.some(order => order.status === 'waiting'))
            {
                const order = ordersToSend.list.filter(order => order.status === 'waiting')[0];

                dispatch({ type: actionTypes.sendOrder, order });

                return HttpService.getInstance().postData(order,`custom/lis/orders`, 'user-token')
                    .then(response => {
                        dispatch({type:actionTypes.acceptOrder, order});
                })
            }
        }

    }
}
import { actionTypes } from '../actions/order';
import HttpService from "../../services/HttpService";

export const receiveSimpleOrder = (order) => ({type:actionTypes.receiveSimpleOrder, order});
export const receiveOrder = (order,patient,studies,measurements, samples) => ({ type: actionTypes.receiveOrder, order,patient,studies,measurements, samples });


export const setOrder = (order) => ({type: actionTypes.setOrder, order});
export const addStudy = (study) => ({type: actionTypes.addStudy, study});
export const reAddStudy = (study) => ({type: actionTypes.reAddStudy, study});
export const editStudy = (study,oldEfectorId) => ({type: actionTypes.editStudy, study,oldEfectorId});
export const removeStudy = (study) => ({type: actionTypes.removeStudy, study});
export const clearStudies = () => ({type: actionTypes.clearStudies});

export const addStudies = (studies) => {
    return (dispatch) => {
        studies.forEach(study => 
            dispatch(addStudy(study)));
    }
}

export const fetchOrder = (orderId) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchOrder });

        return HttpService.getInstance().getData(`orders/${orderId}`, 'user-token')
        .then(response => {
            const {order,patient,studies,measurements,samples} = response.data;
            dispatch(receiveOrder(order,patient,studies,measurements,samples));
        })
    }
}

export const fetchCustomerOrder = (customerId, orderId) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchOrder });

        return HttpService.getInstance().getData(`customers/${customerId}/orders/${orderId}`, 'user-token')
            .then(response => {
                const {order,patient,studies,measurements,samples} = response.data;
                dispatch(receiveOrder(order,patient,studies,measurements,samples));
        })
    }
}

export const createCustomerOrder = (customer, order,studies,samples,measurements) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.createOrder });
        
        const body = {order:order,studies:studies,samples:samples,measurements:measurements};
        return HttpService.getInstance().postData(body,`customers/${customer}/orders`, 'user-token')
            .then(response => {
                const {order,patient,studies,measurements,samples} = response.data;
                dispatch(receiveOrder(order,patient,studies,measurements,samples));
        })
    }
}

export const removeCustomerOrder = (customer, order) => {
    return (dispatch) => {
 
        return HttpService.getInstance().deleteData(`customers/${customer}/orders/${order}`, 'user-token')
            .then(response => {
                const order = response.data;
                dispatch({ type: actionTypes.removeOrder, order});
        })
    }
}

export const createCustomerFastSample = (customer, order,sample,studies,samples,measurements) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.createFastSample });
        
        const body = {order:order,sample:sample,studies:studies,samples:samples,measurements:measurements};
        return HttpService.getInstance().postData(body,`customers/${customer}/fast-samples`, 'user-token')
            .then(response => {
                const {order,patient,studies,measurements,samples} = response.data;
                dispatch(receiveOrder(order,patient,studies,measurements,samples));
        })
    }
}

export const updateCustomerOrder = (customer, order, studies,samples,measurements) => {
    return (dispatch) => {

        const body = {order:order,studies:studies,samples:samples, measurements:measurements};
        return HttpService.getInstance().putData(body, `customers/${customer}/orders`, 'user-token')
            .then(response => {
                const {order,patient,studies,measurements,samples} = response.data;
                dispatch(receiveOrder(order,patient,studies,measurements,samples));
        })
    }
}

export const updateOrder = (order, studies,samples,measurements) => {
    return (dispatch) => {

        const body = {order:order,studies:studies,samples:samples, measurements:measurements};
        return HttpService.getInstance().putData(body, `orders/${order.id}`, 'user-token')
            .then(response => {
                const {order,patient,studies,measurements,samples} = response.data;
                dispatch(receiveOrder(order,patient,studies,measurements,samples));
        })
    }
}
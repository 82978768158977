import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Theme from './ui/Theme';
import { Provider } from "react-redux";
import { BrowserRouter} from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Layout from "./views/Layout";
import { store } from "./state/store";
import { MsalProvider } from "@azure/msal-react";
import ApiHandler from "./components/ApiHandler";

LicenseInfo.setLicenseKey(
  '97a73f5f420df5daed4dd9e623e72708T1JERVI6MzQ0NjQsRVhQSVJZPTE2NzEyNzcwMjQwMDAsS0VZVkVSU0lPTj0x',
);

function App({msalInstance}) {
  return (

    msalInstance ?

    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <StyledEngineProvider injectFirst>
            <BrowserRouter basename={'/'}>
              <ApiHandler/>
              <Layout />
            </BrowserRouter>
          </StyledEngineProvider>
        </ThemeProvider>
      </Provider>
    </MsalProvider> :

    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter basename={'/'}>
            <Layout />
          </BrowserRouter>
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

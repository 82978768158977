import { actionTypes } from '../actions/sampleRanges';
import HttpService from "../../services/HttpService";

export const receiveSampleRanges = (sample_ranges) => ({ type: actionTypes.receiveSampleRanges, sample_ranges });
export const receiveSampleRange = (sample_range) => ({ type: actionTypes.receiveSampleRange, sample_range });

export const fetchSampleRanges = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchSampleRanges });

        return HttpService.getInstance().getData(`resource/sample_ranges`, 'user-token')
            .then(response => {
                dispatch(receiveSampleRanges(response.data));
        })
    }
}

export const createSampleRange = (sample_range) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.createSampleRange });

        return HttpService.getInstance().postData(sample_range,`resource/sample_ranges`, 'user-token')
            .then(response => {
                dispatch(receiveSampleRange(response.data));
        })
    }
}

export const updateSampleRange = (sample_range) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateSampleRange });

        return HttpService.getInstance().putData(sample_range,`resource/sample_ranges/${sample_range.id}`, 'user-token')
        .then(response => {
            dispatch(receiveSampleRange(response.data));
        })
    }
}
import React, { useEffect } from 'react';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { user } from '../state/handlers/profile';
import HttpService from '../services/HttpService';

const AzureLogin = () => {

    const {instance} = useMsal();
    const IsAuthenticated = useIsAuthenticated();
    const dispatch = useDispatch();

    const currentToken = HttpService.getInstance().getLocalToken();
    const currentUser = HttpService.getInstance().getLocalUser();

    useEffect(()=>{

        if(IsAuthenticated){
            if (currentToken !== 'undefined' && currentUser !== 'undefined'){
                dispatch(user());
            }
        }

    },[IsAuthenticated])


    const handleLogin = async () => {
        await instance.loginRedirect({
            scopes:['openid']
        });
    };

    return (

        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Bienvenido a Deliverybook</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em', padding: '3em' }}>
                    <div style={{ marginBottom: '1em' }}>
                        <Button size="small" variant="contained" fullWidth onClick={() => handleLogin()}>Login</Button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AzureLogin;
import { actionTypes, receiveMeasurements } from '../actions/measurement';
import HttpService from "../../services/HttpService";

export const updateCustomerMeasurements = (customer, order, measurements) => {
    return (dispatch) => {

        const body = {measurements:measurements};
        dispatch({ type: actionTypes.updateMeasurements });

        return HttpService.getInstance().putData(body,`customers/${customer}/orders/${order}/measurements`, 'user-token')
            .then(response => {
                dispatch(receiveMeasurements(response.data));
            })
    }
}

export const updateMeasurements = (order, measurements) => {
    return (dispatch) => {

        const body = {measurements:measurements};
        dispatch({ type: actionTypes.updateMeasurements });

        return HttpService.getInstance().putData(body,`orders/${order}/measurements`, 'user-token')
            .then(response => {
                dispatch(receiveMeasurements(response.data));
            })
    }
}
import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { statusIndex } from '../helpers/batchStatus';


const Status = ({status}) => {

    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    const statusStyles = {
        new: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: statusIndex.new, mobileDescription: 'N' },
        inProgress: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.inProgress, borderRadius: 7 }, description: statusIndex.inProgress, mobileDescription: 'PR' },
        partial: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.partial, borderRadius: 7 }, description: statusIndex.partial,mobileDescription: 'P' },
        finished: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.finished, borderRadius: 7 }, description: statusIndex.finished,mobileDescription: 'C' },
        downloaded: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.downloaded, borderRadius: 7 }, description: statusIndex.downloaded, mobileDescription: 'V' },
        delivered: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: statusIndex.delivered, mobileDescription: 'D' },
        changed: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: statusIndex.changed, mobileDescription: 'C' }
    }

    return (
        <Box fontWeight={'600'}>
            <Typography 
                style={statusStyles[status].style}
                align="center"
                variant="body2">
                {matchMD ? statusStyles[status].mobileDescription: statusStyles[status].description}
            </Typography >
        </Box>

    );
};

export default Status;

export const orderStatusList = [
    {value:'all',translate:'Todos'},
    {value:'new',translate:'Nuevo'},
    {value:'delivered',translate:'Enviado'},
    {value:'inProgress',translate:'En progreso'},
    {value:'partial',translate:'Parcial'},
    {value:'finished',translate:'Finalizado'},
    {value:'downloaded',translate:'Visto'}
]


export const statusIndex = {
    new : "NUEVO",
    inProgress: "EN PROGRESO",
    delivered: "ENVIADO",
    finished: "FINALIZADO",
    partial: "PARCIAL",
    canceled:"CANCELADO",
    pending:'PENDIENTE',
    printed:'IMPRESO',
    downloaded:'VISTO',
    changed:'EDITADO',
}

const statusProgress = {
    new : 0,
    inProgress: 60,
    delivered: 30,
    partial: 80,
    finished: 100,
    canceled:100
}

export const translate = (status) => {
    return statusIndex[status] ? statusIndex[status] : status;
}

export const progress = (status) => {
    return statusProgress[status] ? statusProgress[status] : 0;
}
export const actionTypes = {
    fetchOrder: 'FETCH_ORDER',
    receiveOrder: 'RECEIVE_ORDER',
    receiveSimpleOrder: 'RECEIVE_SIMPLE_ORDER',
    editOrder:'EDIT_ORDER',
    removeOrder: 'REMOVE_ORDER',
    createOrder:'CREATE_ORDER',
    createFastSample:'CREATE_FAST_SAMPLE',
    updateOrder:'UPDATE_ORDER',
    clearOrder : 'CLEAR_ORDER',
    setOrder:'SET_ORDER',
    addStudy:'ADD_STUDY',
    reAddStudy:'RE_ADD_STUDY',
    editStudy:'EDIT_STUDY',
    removeStudy:'REMOVE_STUDY',
    clearStudies:'CLEAR_STUDIES',
    mergeSample:'MERGE_SAMPLE',
};

export const clearOrder = { type: actionTypes.clearOrder };
export const editOrder = (order) => ({type:actionTypes.editOrder, order});
export const mergeSample = (study,oldSample,sample) => ({type:actionTypes.mergeSample, study,oldSample,sample});

import React, { useEffect, useState } from "react";

import { Box, Button,  Divider,  IconButton,  MenuItem,  Paper,  TextField, Typography } from "@mui/material";
import '../styles/Views.css';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { orderStatusList, progress, translate } from "../helpers/batchStatus";
import { selectProfile } from "../state/selectors/profile";
import { LocationSearching, Search, SearchOffOutlined, Visibility } from "@mui/icons-material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { format, parseISO } from "date-fns";
import OrderStatus from "./OrderStatus";
import { selectExplorer } from "../state/selectors/explorer";
import { fetchCustomerExplorerSamples, fetchExplorerSamples } from "../state/handlers/explorer";
import { selectCustomers } from "../state/selectors/catalogs.customers";
import { fetchCustomers } from "../state/handlers/catalogs.customer";
import { translatePriority } from "../helpers/priorities";


const columns = [
    {
        field: 'sample_id',
        headerName: 'Id',
        width: 30,
        sortable: false,
        type: 'number',
        hide:true,
    },
    {
        field: 'locator',
        headerName: 'Muestra',
        sortable: false,
        type: 'string',
        width: 120,
    },
    {
        field: 'order_id',
        headerName: 'Id de orden',
        sortable: false,
        flex: 0.3,
        valueGetter:(params)=> params.row.order.id,
        minWidth: 110
    },
    {
        field: 'number',
        headerName: 'Protocolo',
        sortable: false,
        flex: 0.3,
        valueGetter:(params)=> params.row.order.number,
        minWidth: 110
    },
    {
        field: 'patient_number',
        headerName: 'Identificacion',
        sortable: false,
        type: 'string',
        flex: 0.3,
        valueGetter: (params) => `${params.row.order?.patient?.number || ''}`
    },
    {
        field: 'patient',
        headerName: 'Paciente',
        sortable: false,
        type: 'string',
        flex: 0.8,
        valueGetter: (params) => `${params.row.order?.patient?.first_name || ''} ${params.row.order?.patient?.last_name || ''}`
    },
    {
        field: 'priority',
        headerName: 'Prioridad',
        sortable: false,
        type: 'string',
        flex: 0.3,
        minWidth: 85,
        valueGetter: (params) => translatePriority(params.row.order.prioriry)
    },
    {
        field: 'batch_id',
        headerName: 'Lote',
        sortable: false,
        flex: 0.3,
        valueGetter:(params)=> params.row.order.batch_id,
        minWidth: 110
    },
    {
        field: 'customer',
        headerName: 'Cliente',
        sortable: false,
        flex: 0.5,
        valueGetter:(params)=> params.row.order.customer.name,
        minWidth: 110
    },
    {
        field: 'date',
        headerName: 'Fecha',
        sortable: false,
        flex: 0.5,
        valueGetter:(params)=>format(parseISO(params.row.order.date), 'dd/MM/yyyy'),
        minWidth: 110
    },
    {
        field: 'status',
        headerName: 'Estado',
        sortable: false,
        flex: 0.5,
        renderCell: (params) => <OrderStatus status={params.row.order.status}/>,
        minWidth: 110
    },
    {
        field: 'view_order',
        headerName: 'Ver orden',
        sortable: false,
        renderCell: (params) => <Link to={`/order/${params.row.order.id}`}><Button startIcon={<Visibility />}/></Link>,
    }
];


const Explorer = () => {

const profile = useSelector(selectProfile);
const [filter, setFilter] = useState('');

const [locator,setLocator] = useState('');
const [number,setNumber] = useState('');
const [patient,setPatient] = useState('');
const [when,setWhen] = useState('today');
const [customer,setCustomer] = useState('all');
const [status,setStatus] = useState('all');

const isCustomer = profile.data?.role.code === 'customer';
const customerId = profile.data?.customer?.id;

const dispatch = useDispatch();

const {loading,list} = useSelector(selectExplorer);

useEffect(() => {
    !isCustomer && dispatch(fetchCustomers());
}, [isCustomer]);

const customers = useSelector(selectCustomers);

const whenList = [
    {
        value:'today',
        description:'Hoy'
    },
    {
        value:'yesterday',
        description:'Ayer'
    },
    {
        value:'lastWeek',
        description:'Esta semana'
    },
    {
        value:'lastFortnight',
        description:'Esta quincena'
    },

]

const data = {rows:list, columns:columns};

const handleSearch = (filter) => {

    if(isCustomer){
        dispatch(fetchCustomerExplorerSamples(customerId,filter));
    }
    
    if(!isCustomer){
        dispatch(fetchExplorerSamples(filter));
    }
}

return ( 
    <div className="column-container all-height" >
        <div className="header row-container align-center" style={{height:'4em'}}>
            <div>
                <Typography variant="h5" color="text.black">
                    <Box fontWeight={750}>Explorador</Box>
                </Typography>
            </div>
        </div>

        <div className="row-container body grow" style={{marginBottom:'2em'}}>
            <div className="column-container" style={{ flex:1, marginRight:'2em', marginTop:'2em'}}>

                <div className="row-container" style={{marginBottom:'1em'}}>
                    <TextField 
                        variant="outlined" 
                        sx={{marginRight:'0.5em'}} 
                        label="Muestra" 
                        size="small" 
                        fullWidth 
                        value={locator}
                        onChange={(event)=>setLocator(event.target.value)} 
                        onKeyUp={(event)=>(event.key === 'Enter' || event.keyCode === 13) && handleSearch(`locator=${locator}`)}
                    >
                    </TextField>
                    <Button
                            style={{ height: '2.8em' }}
                            variant="contained"
                            color="secondary"
                            onClick={()=>handleSearch(`locator=${locator}`)}
                        >
                            <LocationSearching />
                    </Button>
                </div>
                <div className="row-container" style={{marginBottom:'1em'}}>
                    <TextField 
                        variant="outlined" 
                        sx={{marginRight:'0.5em'}} 
                        label="Protocolo" 
                        size={"small"} 
                        fullWidth
                        value={number}
                        onChange={(event)=>setNumber(event.target.value)} 
                        onKeyUp={(event)=>(event.key === 'Enter' || event.keyCode === 13) &&  handleSearch(`number=${number}`)}
                    >
                    </TextField>
                        <Button
                                style={{ height: '2.8em' }}
                                variant="contained"
                                color="secondary"
                                onClick={()=>handleSearch(`number=${number}`)}
                            >
                                <LocationSearching />
                        </Button>
                </div>

                <div className="row-container" style={{marginBottom:'4em'}}>
                    <TextField 
                        variant="outlined" 
                        sx={{marginRight:'0.5em'}} 
                        label="Identificacion" 
                        size={"small"} 
                        fullWidth
                        value={patient}
                        onChange={(event)=>setPatient(event.target.value)} 
                        onKeyUp={(event)=>(event.key === 'Enter' || event.keyCode === 13) &&  handleSearch(`patient=${patient}`)}
                    >
                    </TextField>
                        <Button
                                style={{ height: '2.8em' }}
                                variant="contained"
                                color="secondary"
                                onClick={()=>handleSearch(`patient=${patient}`)}
                            >
                                <LocationSearching />
                        </Button>
                </div>


                <div className="column-container">
                    <TextField 
                        sx={{marginBottom:'1em'}} 
                        variant="outlined" 
                        size="small" 
                        label="Cuando"
                        value={when}
                        select
                        onChange={(event)=>setWhen(event.target.value)}
                    >
                        {
                            whenList.map(when => (
                                <MenuItem key={when.value} value={when.value}>
                                    {when.description}
                                </MenuItem>
                            ))
                        }
                    </TextField>

                    {
                        !isCustomer && 
                        
                        <TextField 
                            sx={{marginBottom:'1em'}} 
                            variant="outlined" 
                            size="small" 
                            label="Cliente"
                            select
                            value={customer}
                            onChange={(event)=>setCustomer(event.target.value)}
                        >

                                <MenuItem key={'all'} value={'all'}>
                                    {'Todos'}
                                </MenuItem>

                        {
                            customers.map(customer => (
                                <MenuItem key={customer.id} value={customer.id}>
                                    {customer.name}
                                </MenuItem>
                            ))
                        }


                        </TextField>
                            
                    }

                    <TextField 
                        sx={{marginBottom:'1em'}} 
                        variant="outlined" 
                        size="small" 
                        label="Estado"
                        value={status}
                        select
                        onChange={(event)=>setStatus(event.target.value)}
                    >
                        {
                            orderStatusList.map(status => (
                                <MenuItem key={status.value} value={status.value}>
                                    {status.translate}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                    <Button 
                        variant="contained"
                        color="secondary"
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}} 
                        onClick={()=>handleSearch(`when=${when}&status=${status}&customer=${customer || customerId}`)}
                    >
                        <Typography>Buscar</Typography>
                    </Button>
                </div>

            </div>

            <div className="column-container" style={{flex:5, marginTop:'2em'}}>
                <DataGridPro
                        {...data}
                        headerHeight={40}
                        rowHeight={40}
                        disableMultipleSelection
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleColumnsSorting
                        hideFooter
                    />

            </div>
        </div>
    </div>
    );
};

export default Explorer;

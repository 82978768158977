import { combineReducers } from 'redux';
import { actionTypes } from '../actions/catalogs.customer';

const current = (state = {},action) => {
    switch (action.type) {
        case actionTypes.clearCustomer:
            return {}
        case actionTypes.receiveCustomer:
            return action.customer
        case actionTypes.setCustomer:
            return action.customer
        default:
            return state
    }
};

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchCustomers:
            return []
        case actionTypes.receiveCustomers:
            return action.customers
        default:
            return state
    }
}

const loading = (state=false, action) => {
    switch (action.type) {
        case actionTypes.fetchCustomers:
            return true
        case actionTypes.receiveCustomers:
            return false
        default:
            return state
    }
}

const saving = (state=false, action) => {
    switch (action.type) {
        case actionTypes.saveCustomer:
            return true
        case actionTypes.receiveCustomer:
            return false
        case actionTypes.clearCustomer:
            return false
        case actionTypes.setCustomer:
            return false
        default:
            return state
    }
}

const saved = (state=false, action) => {
    switch (action.type) {
        case actionTypes.receiveCustomer:
            return true
        case actionTypes.clearCustomer:
            return false
        case actionTypes.setCustomer:
            return false
        default:
            return state
    }
}


export default combineReducers({ loading, saving, current, list,saved });
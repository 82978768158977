import { combineReducers } from 'redux';
import { actionTypes } from '../actions/patients';

const searching = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchPatients:
            return true
        case actionTypes.receivePatients:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receivePatients:
            return action.patients
        case actionTypes.clearPatients:
            return []
        default:
            return state
    }
}
export default combineReducers({ searching, data });


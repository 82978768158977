import { actionTypes } from '../actions/batches';
import HttpService from "../../services/HttpService";

const receiveBatches = (batches) => ({ type: actionTypes.receiveBatches, batches });

export const fetchBatches = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.fetchBatches });

        return HttpService.getInstance().getData(`batches`, 'user-token')
        .then(response => {
            dispatch(receiveBatches(response.data));
        })
    }
}

export const fetchCustomerBatches = (customerId) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.fetchBatches });

        return HttpService.getInstance().getData(`customers/${customerId}/batches`,'user-token')
        .then(response => {
            dispatch(receiveBatches(response.data));
        })
    }
}
import { actionTypes as orderActionTypes } from '../../actions/order';
import { actionTypes } from '../../actions/measurement';

const measurements = (state = [], action) => {
    switch (action.type) {

        case actionTypes.editMeasurement:
            return [...state.map(measurement => measurement.id !== action.id ? measurement : {...measurement, value:action.value})]
        case orderActionTypes.clearOrder:
            return []
        case orderActionTypes.receiveOrder:
            return action.measurements
        case orderActionTypes.addStudy:

            const studyTypesToAdd = !action.study.study_type.isRoutine ? [action.study.study_type] : action.study.study_type.study_types;
            
            studyTypesToAdd.forEach(studyTypeToAdd => {

                studyTypeToAdd.measurement_types?.forEach(measurementType =>{
                    if(state.some(measurement=>measurement.measurement_type.code === measurementType.code)){
                        const measurementOnState = state.filter(measurement=>measurement.measurement_type.code === measurementType.code)[0];
                        state=[
                                ...state.filter(measurement => measurement.measurement_type.code !== measurementOnState.measurement_type.code), 
                                {...measurementOnState, studies:[...measurementOnState.studies, {study_type:{id:studyTypeToAdd.id, code:studyTypeToAdd.code, name:studyTypeToAdd.name}}]}
                        ];
                    }
                    else{
                        state=[
                                ...state, 
                                {measurement_type:{...measurementType}, studies:[{study_type:{id:studyTypeToAdd.id, code:studyTypeToAdd.code, name:studyTypeToAdd.name}}]}
                        ];
                    }
                })


                action.study.study_type.sample_types.forEach(sampleType => {
                    sampleType.measurement_types?.forEach(measurementType=>{
                        if(!state.some(measurement=>measurement.measurement_type.code === measurementType.code && measurement.sample.sample_type.code === sampleType.code)){
                            state=[...state, {measurement_type:{...measurementType}, sample:{sample_type:{...sampleType}}}];
                        }
                    })
                })
            })

            return state
        case orderActionTypes.removeStudy:

            action.study.study_type.measurement_types?.forEach(measurementType =>{
                if(state.some(measurement=>measurement.measurement_type.code === measurementType.code)){
                    const measurementOnState = state.filter(measurement=>measurement.measurement_type.code === measurementType.code)[0];
                    
                    if (measurementOnState.studies.length === 1){
                        state=[...state.filter(measurement=> measurement.measurement_type.code != measurementOnState.measurement_type.code)];
                    }
                    else{
                        state=[
                            ...state.filter(measurement=> measurement.measurement_type.code != measurementOnState.measurement_type.code), 
                            {...measurementOnState, studies:[...measurementOnState.studies.filter(study=>study.study_type.code !== action.study.study_type.code)]}
                        ];
                    }
                }
            })

            action.study.samples.forEach(sample => {
                const {sample_type} = sample;
                sample_type.measurement_types?.forEach(measurementType=>{
                    if(state.some(measurement=>measurement.measurement_type.code === measurementType.code && measurement.sample_type_code === sample_type.code)){
                        state=[...state.filter(measurement=> measurement.measurement_type.code !== measurementType.code && measurement.sample_type_code !== sample_type.code)];
                    }
                })
            })

            return state
        default:
            return state
    }
}

export default measurements;


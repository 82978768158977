
export const selectOrdersToSend = (state) => {
    return state.ordersToSend;
}

export const selectSendableOrders = (state) => {
    return state.batch?.data?.orders?.map(
        order => (
            {
                id:order.id, 
                number:order.number, 
                status:'waiting',
                error:''
            }));
}


export const genders = [
    {gender:'male', description:'Masc'},
    {gender:'female', description:'Fem'}, 
    {gender:'', description:''}
];

export const translate = (value) => {
    
     const gender = genders.filter(g=>g.gender === value)[0];

     if (gender){
        return gender.description;
     }
    
    return "";
} 
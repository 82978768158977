import React, { useEffect, useState } from 'react';

import { Button, Checkbox, FormControlLabel, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectProfile } from '../state/selectors/profile';
import { login, user } from '../state/handlers/profile';

import Cookies from 'js-cookie';
import { fetchCatalogs } from '../state/handlers/catalogs';

const PassportLogin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(selectProfile);
    const [credentials, setCredentials] = useState({ username: '', password: '', remeber: false });
    const token = profile?.data?.access_token || Cookies.get('token');

    useEffect(() => {
        if (token) {
            if (profile.data && profile.data.loginSuccessful) {
                dispatch(fetchCatalogs());
                navigate(`/dashboard`);
            }
        }
    }, [profile.data, token]);

    useEffect(() => {
        if (token && !profile?.data?.id) {
            dispatch(user());
        }
    }, [token]);

    const handleLogin = () => {
        dispatch(login(credentials));
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Bienvenido a Deliverybook</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em', padding: '3em' }}>

                    <div style={{ marginBottom: '1em' }}>
                        <TextField size="small" variant="outlined" placeholder="Email" fullWidth value={credentials.username} onChange={(event) => setCredentials({ ...credentials, username: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField size="small" type="password" variant="outlined" placeholder="Password" fullWidth value={credentials.password} onChange={(event) => setCredentials({ ...credentials, password: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <FormControlLabel
                            label="Mantener sesion iniciada"
                            control={<Checkbox checked={credentials.remember} onChange={(event) => setCredentials({ ...credentials, remember: event.target.checked })} />}
                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Button size="small" variant="contained" fullWidth onClick={() => handleLogin()}>Login</Button>
                    </div>
                    <div style={{ height: '1em' }}>
                        {
                            profile.loading && <LinearProgress color="primary" />
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PassportLogin;
export const arencoPantone = {
    darkBlue: "#052443",
    mediumBlue: "#003152",
    lightBlue: "#0e4b6c",
    green: '#b1ded3',
    gray: '#DADBD0',
    white:'#000000'
}

export const arencoColorTheme = {
    primary:arencoPantone.white,
    secondary:arencoPantone.lightBlue
}

export const arencoStatusColorTheme = {
    cancelled: '#ee7a7d',
    pending: '#FF9932',
    inProgress:'#666666',
    finished: '#3B3B3B',
    downloaded:'#009688'
}
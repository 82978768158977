import { combineReducers } from 'redux';
import { actionTypes } from '../actions/resultsExplorer';

const currentState = {
    loading:false,
    order:{},
    samples:[]
}

const searchCriteriaState = {
    sampleLocator:'',
    orderNumber:'',
    patientIdentificacion:'',
    when:'today',
    status: {key:2, values:['partialOrFinished'],translate:'Parcial / Finalizado'},
    customer: null
};

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchResultsExplorerOrders:
            return true
        case actionTypes.receiveResultsExplorerOrders:
            return false
        default:
            return state
    }
}

const searchCriteria = (state=searchCriteriaState,action) => {
    switch (action.type) {
        case actionTypes.setResultsExplorerSearchCriteria:
            return action.criteria
        default:
            return state
    }
}

const list = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receiveResultsExplorerOrders:
            return action.orders
        case actionTypes.clearResultsExplorerOrders:
            return []
        default:
            return state
    }
}

const current = (state = currentState, action) => {
    switch(action.type){
        case actionTypes.fetchCurrentResults:
            return {...state,loading:true, samples:[]}
        case actionTypes.receiveCurrentResultsExplorerResults:
            return {...state,loading:false, samples:action.samples}
        case actionTypes.selectCurrentResultsExplorerOrder:
            return {...state, order:action.order, samples:[]}
        case actionTypes.clearCurrentResultsExplorerOrder:
            return currentState
        default:
            return state
    }

}

export default combineReducers({ loading, list, current, searchCriteria });


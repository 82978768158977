export const actionTypes = {
    fetchMeasurementTypes:'FETCH_MEASUREMENT_TYPES',
    receiveMeasurementTypes:'RECEIVE_MEASUREMENT_TYPES',
    createMeasurementType:'CREATE_MEASUREMENT_TYPE',
    updateMeasurementType:'UPDATE_MEASUREMENT_TYPE',
    receiveMeasurementType:'RECEIVE_MEASUREMENT_TYPE',
    setMeasurementType:'SET_MEASUREMENT_TYPE',
    fetchMeasurementStudyTypes:'FETCH_MEASUREMENT_STUDY_TYPES',
    fetchMeasurementSampleTypes:'FETCH_MEASUREMENT_SAMPLE_TYPES',
    receiveMeasurementStudyTypes:'RECEIVE_MEASUREMENT_STUDY_TYPES',
    receiveMeasurementSampleTypes:'RECEIVE_MEASUREMENT_SAMPLE_TYPES',
    receiveMeasurementStudyType:'RECEIVE_MEASUREMENT_STUDY_TYPE',
    receiveMeasurementSampleType:'RECEIVE_MEASUREMENT_SAMPLE_TYPE',
    clearMeasurementStudyTypes:'CLEAR_MEASUREMENT_STUDY_TYPES',
    clearMeasurementSampleTypes:'CLEAR_MEASUREMENT_SAMPLE_TYPES',
    addMeasurementStudyTypes:'ADD_MEASUREMENT_STUDY_TYPES',
    addMeasurementSampleTypes:'ADD_MEASUREMENT_SAMPLE_TYPES',
    removeMeasurementStudyTypes:'REMOVE_MEASUREMENT_STUDY_TYPES',
    removeMeasurementSampleTypes:'REMOVE_MEASUREMENT_SAMPLE_TYPES'
 };



import React, { useState } from "react"
import { Button, MenuItem, TextField, Typography } from "@mui/material"
import { LocationSearching } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux";
import { selectProfile } from "../state/selectors/profile";
import { selectCustomers } from "../state/selectors/catalogs.customers";
import { selectSearchCriteria } from "../state/selectors/resultsExplorer";
import { setResultsExplorerSearchCriteria } from "../state/handlers/resultsExplorer";

const statusCriteria = [
    {key:1, values:['all'],translate:'Todos'},
    {key:2, values:['partialOrFinished'],translate:'Parcial / Finalizado'},
    {key:3, values:['partial'],translate:'Parcial'},
    {key:4, values:['finished'],translate:'Finalizado'},
    {key:5, values:['downloaded'],translate:'Visto'}
]

const whenList = [
    {
        value:'today',
        description:'Hoy'
    },
    {
        value:'yesterday',
        description:'Ayer'
    },
    {
        value:'lastWeek',
        description:'Ultimos 7 dias'
    },
    {
        value:'lastFortnight',
        description:'Ultimos 15 dias'
    },
    {
        value:'lastMonth',
        description:'Ultimos 30 dias'
    },
    {
        value:'lastQuarter',
        description:'Ultimos 90 dias'
    }

];

const ResultsSearch = ({onSearch}) => {

    const dispatch = useDispatch();

    const searchCriteria = useSelector(selectSearchCriteria);
    const profile = useSelector(selectProfile);
    const customers = useSelector(selectCustomers);
    const isCustomer = profile.data?.role.code === 'customer';

    const handleSearch = (filter) =>
    {
        onSearch(filter);
    }

    const setCriteria = (criteria) =>
    {
        dispatch(setResultsExplorerSearchCriteria(criteria));
    } 

    const handleChangeStatus = (key) =>
    {
        const status = statusCriteria.find(s=>s.key === key);
        setCriteria({...searchCriteria, status:status})
    }

    return(
        <div className="column-container" style={{marginRight:'2em', marginTop:'2em'}}>
            <div className="row-container" style={{marginBottom:'1em'}}>
                <TextField 
                    variant="outlined" 
                    sx={{marginRight:'0.5em'}} 
                    label="Muestra" 
                    size="small" 
                    fullWidth 
                    value={searchCriteria.sampleLocator}
                    onChange={(event)=>setCriteria({...searchCriteria, sampleLocator:event.target.value})} 
                    onKeyUp={(event)=>(event.key === 'Enter' || event.keyCode === 13) && handleSearch(`locator=${searchCriteria.sampleLocator}`)}
                >
                </TextField>
                <Button
                        style={{ height: '2.8em' }}
                        variant="contained"
                        color="secondary"
                        onClick={()=>handleSearch(`locator=${searchCriteria.sampleLocator}`)}
                    >
                        <LocationSearching />
                </Button>
            </div>
            <div className="row-container" style={{marginBottom:'1em'}}>
                <TextField 
                    variant="outlined" 
                    sx={{marginRight:'0.5em'}} 
                    label="Protocolo" 
                    size={"small"} 
                    fullWidth
                    value={searchCriteria.orderNumber}
                    onChange={(event)=>setCriteria({...searchCriteria, orderNumber:event.target.value})} 
                    onKeyUp={(event)=>(event.key === 'Enter' || event.keyCode === 13) &&  handleSearch(`number=${searchCriteria.orderNumber}`)}
                >
                </TextField>
                <Button
                        style={{ height: '2.8em' }}
                        variant="contained"
                        color="secondary"
                        onClick={()=>handleSearch(`number=${searchCriteria.orderNumber}`)}
                    >
                        <LocationSearching />
                </Button>
            </div>

            <div className="row-container" style={{marginBottom:'4em'}}>
                <TextField 
                    variant="outlined" 
                    sx={{marginRight:'0.5em'}} 
                    label="Identificacion" 
                    size={"small"} 
                    fullWidth
                    value={searchCriteria.patientIdentificacion}
                    onChange={(event)=>setCriteria({...searchCriteria,patientIdentificacion:event.target.value})} 
                    onKeyUp={(event)=>(event.key === 'Enter' || event.keyCode === 13) &&  handleSearch(`identification=${searchCriteria.patientIdentificacion}`)}
                >
                </TextField>
                <Button
                        style={{ height: '2.8em' }}
                        variant="contained"
                        color="secondary"
                        onClick={()=>handleSearch(`identification=${searchCriteria.patientIdentificacion}`)}
                    >
                        <LocationSearching />
                </Button>
            </div>


            <div className="column-container">
                <TextField 
                    sx={{marginBottom:'1em'}} 
                    variant="outlined" 
                    size="small" 
                    label="Cuando"
                    value={searchCriteria.when}
                    select
                    onChange={(event)=>setCriteria({...searchCriteria, when:event.target.value})}
                >
                    {
                        whenList.map(when => (
                            <MenuItem key={when.value} value={when.value}>
                                {when.description}
                            </MenuItem>
                        ))
                    }

                </TextField>

                <TextField 
                    sx={{marginBottom:'1em'}} 
                    variant="outlined" 
                    size="small" 
                    label="Estado"
                    value={searchCriteria.status.key}
                    select
                    onChange={(event)=>handleChangeStatus(event.target.value)}
                >
                    {
                        statusCriteria.map(status => (
                            <MenuItem key={status.key} value={status.key}>
                                {status.translate}
                            </MenuItem>
                        ))
                    }

                </TextField>


                {
                    !isCustomer && 
                    
                    <TextField 
                        sx={{marginBottom:'1em'}} 
                        variant="outlined" 
                        size="small" 
                        label="Cliente"
                        select
                        value={searchCriteria.customer}
                        onChange={(event)=>setCriteria({...searchCriteria, customer: event.target.value})}
                    >
                        <MenuItem key={'all'} value={'all'}>
                            {'Todos'}
                        </MenuItem>

                        {
                            customers.map(customer => (
                                <MenuItem key={customer.id} value={customer.id}>
                                    {customer.name}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                }

                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}} 
                    onClick={()=>handleSearch(`when=${searchCriteria.when}&status=${searchCriteria.status.values}${searchCriteria.customer ? `&customer=${searchCriteria.customer}`:''}`)}
                >
                    <Typography>Buscar</Typography>
                </Button>

            </div>
        </div>
    )
}

export default ResultsSearch;

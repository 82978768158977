import { actionTypes } from '../actions/patient';
import HttpService from "../../services/HttpService";

const receivePatient = (patient) => ({ type: actionTypes.receivePatient, patient });

export const createPatient = (customer, patient) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.createPatient });
        return HttpService.getInstance().postData(patient,`customers/${customer}/patients`, 'user-token')
            .then(response => {
                dispatch(receivePatient(response.data));
            })
            .catch(error => {
                dispatch({ type: actionTypes.clearPatient});
            })
    }
}

export const updatePatient = (customer, patient) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.updatePatient });
        return HttpService.getInstance().putData(patient, `customers/${customer}/patients`, 'user-token')
            .then(response => {
                dispatch(receivePatient(response.data));
        })
    }
}
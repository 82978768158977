export const actionTypes = {
    setProfile: "SET_PROFILE",
    fetchProfile: "FETCH_PROFILE",
    tryLogin: 'TRY_LOGIN',
    clearProfile: "CLEAR_PROFILE",
    loginFailed: "LOGIN_FAILED",
    setTokens: 'SET_TOKENS',
};

export const clearProfile = () => ({ type: actionTypes.clearProfile })
export const setProfile = (profile) => ({ type: actionTypes.setProfile, profile })
export const setTokens = (tokens) => ({ type: actionTypes.setTokens, tokens })

import { actionTypes } from '../../actions/patient';
import { actionTypes as orderActionTypes } from '../../actions/order';

const patient = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.clearPatient:
            return {}
        case actionTypes.receivePatient:
            return action.patient
        case orderActionTypes.receiveOrder:
            return action.patient
        case orderActionTypes.clearOrder:
            return {}
        case actionTypes.setPatient:
            return {...state, ...action.patient}
        default:
            return state
    }}

export default patient;
import { combineReducers } from 'redux';
import { actionTypes } from '../actions/routines';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchRoutines:
            return true
        case actionTypes.createRoutine:
            return true
        case actionTypes.updateRoutine:
            return true
        case actionTypes.receiveRoutine:
            return false
        case actionTypes.receiveRoutines:
            return false
        default:
            return state
    }
}

const current = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.fetchRoutines:
            return {}
        case actionTypes.setRoutine:
            return action.routine
        case actionTypes.receiveRoutine:
            return action.routine
        case actionTypes.receiveRoutineStudies:
            return {...state, studyTypes:action.codes}
        default:
            return state
    }
}

const list = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.fetchRoutines:
            return []
        case actionTypes.receiveRoutines:
            return action.routines
        case actionTypes.receiveRoutine:
            return [...state.filter(mt=>mt.id !== action.routine.id), action.routine]
        case actionTypes.receiveRoutineStudies:

            let routine = state.filter(mt=>mt.id === action.id)[0];
            routine.studyTypes = action.codes;

            return [...state.filter(mt=>mt.id !== action.id), routine]
        default:
            return state
    }
}

export default combineReducers({ loading, current, list });
import React from "react";
import '../../styles/Views.css';
import { Box, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import OrderStatus from "../OrderStatus";
import { translatePriority } from "../../helpers/priorities";

const OrderData = ({order}) => {
    return(
        <div className="column-container">
            <div className="column-container justify-start" >
                    <div className="row-container align-center ">
                        <Box fontWeight="medium" sx={{marginRight:'1em'}}>
                            <Typography variant="h4" color="text.black">{`Protocolo ${order.number ?? '(vacio)'}`}</Typography>
                        </Box>
                        <OrderStatus status={order.status}/>
                    </div>
                    <div className="row-container padding">
                        <div className="column-container flex-1">
                            <Typography color="text.gray" variant="body1">Id</Typography>
                            <Typography color="text.gray" variant="body1">Fecha</Typography>
                            <Typography color="text.gray" variant="body1">Prioridad</Typography>
                            <Typography color="text.gray" variant="body1">Observacion</Typography>
                        </div>
                        
                        <div className="column-container flex-3">
                            <Typography color="text.gray" variant="body1">{order.id}</Typography>
                            <Typography color="text.gray" variant="body1">{format(parseISO(order.date), 'dd/MM/yyyy')}</Typography>
                            <Typography color="text.gray" variant="body1">{translatePriority(order.priority)}</Typography>
                            <Typography color="text.gray" variant="body1">{order.observation ?? ''}</Typography>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default OrderData;
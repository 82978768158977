import React, { Fragment, useState } from "react";
import envs from "../services/EnvsService";
import { useDispatch } from "react-redux";
import { editStudy, reAddStudy, removeStudy } from "../state/handlers/order";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddCircle, Cached, Delete, KeyboardDoubleArrowRight, MergeSharp, TransferWithinAStation } from "@mui/icons-material";
import { mergeSample } from "../state/actions/order";


const SampleSelector = ({sample,samples, onSelect}) => {

    const [selected,select] = useState(null);

    const handleSelect = (value) => {
        select(value);
        onSelect(value);
    };

    return (                            
    
        <div className="row-container align-center">

            <div className="flex-1">
                <TextField 
                    variant="outlined"
                    fullWidth
                    value={`${sample?.sample_type?.name} ${sample?.efector?.name || ''} ${sample?.locator || ''}`}></TextField>
            </div>

            <div style={{marginLeft:'2em', marginRight:'2em'}}>
                <KeyboardDoubleArrowRight fontSize="large"/>
            </div>

            <div className="flex-1">

                {
                    !samples.some(s=>s.sample_type.code === sample.sample_type.code) &&
                    <Typography>No se puede fusionar</Typography>
                }

                {
                    samples.some(s=>s.sample_type.code === sample.sample_type.code) &&
                    <TextField
                        variant="outlined"
                        select
                        value={selected || ''}
                        fullWidth
                        onChange={(event)=>handleSelect(event.target.value)}
                    >
                        {
                            samples.filter(s=>s.sample_type.code === sample.sample_type.code).map(
                                item => {
                                    return <MenuItem key={item.id} value={item}>
                                        <Typography variant="body2">{`${item?.sample_type?.name} ${item?.efector?.name || ''} ${item?.locator || ''}`}</Typography>
                                    </MenuItem>;
                            })
                        }
                    </TextField>
                }
            </div>
        </div>
    )

}

const SamplesDialog = ({open,onClose,study,samples}) => {
    const [merges,addMerge] = useState([]);
    
    const handleMerge = (oldSample,sample) => {
        addMerge([...merges,{oldSample:oldSample, sample:sample}]);
    }

    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle>Muestras del estudio</DialogTitle>
            <DialogContent style={{ padding:'2em', minWidth:'40em',maxWidth:'40em'}}>
                <div className="column-container">
                    {
                        study.samples.map(sample=> <SampleSelector sample={sample} samples={samples} onSelect={(selected)=>handleMerge(sample, selected)}/>)
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={()=>onClose([])}>Cancelar</Button>
                <Button variant="contained" color="secondary" diabled={!merges.length} onClick={()=>onClose(merges)}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    )
}


export const OrderStudiesGrid = ({studies,samples,efectors,disabled}) => {

    const {allowSelectEfector} = envs;
    const [selected,select] = useState(null);

    const dispatch = useDispatch();

    const handleRemoveStudy = (study) => {
        dispatch(removeStudy(study));
    }

    const handleAddStudy = (study) => {
        dispatch(reAddStudy(study));
    };

    const handleEditStudy = (study, oldEfectorId) => {
        dispatch(editStudy(study,oldEfectorId));
    };

    const handleMergeSample = (merges) => {
        const study = selected;
        select(null);
        merges.forEach(m=>dispatch(mergeSample(study,m.oldSample,m.sample)));
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            hide:true,
            sortable: false,
            type: 'number'
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            width: 80,
            renderCell: (params) => <Typography variant="body2" style={params.row.mark_remove ? {textDecoration:'line-through'}:{}}>{params.row.study_type.code}</Typography>,
        },
        {
            field: 'name',
            headerName: 'Descripcion',
            sortable: false,
            type: 'string',
            flex: 1,
            minWidth: 50,
            renderCell: (params) => <Typography variant="body2" style={params.row.mark_remove ? {textDecoration:'line-through'}:{}}>{params.row.study_type.name}</Typography>,

        },
        {
            field: 'efector_id',
            headerName: 'Efector',
            sortable: false,
            type: 'string',
            hide:!allowSelectEfector,
            width: 160,
            
            renderCell: (params) => 
            
                <TextField
                    id="code"
                    variant="standard"
                    style={params.row.mark_remove ? {textDecoration:'line-through'}:{}}
                    select
                    value={params.row.efector?.id || ''}
                    fullWidth
                    disabled={disabled}
                    size="small"
                    inputProps={{sx:{paddingBottom:0}}}
                    InputProps={{disableUnderline: true}}
                    onChange={(event) => handleEditStudy({...params.row, efector: efectors.filter(e=>e.id == event.target.value)[0], efector_id : event.target.value},params.row.efector.id)}
                >
                        {
                            efectors.map(
                                item => {
                                    return <MenuItem key={item.id} value={item.id}>
                                        <Typography variant="body2">{item.name}</Typography>
                                    </MenuItem>;
                                })
                        }
                </TextField>
        },
        {
            field: 'samples',
            headerName: 'Muestras',
            sortable: false,
            renderCell: (params) => <IconButton style={{color:params.row.merged ? "#009688" : "#696969"}} onClick={()=>select(params.row)}><Cached/></IconButton>
        },
        {
            field: 'remove',
            headerName: 'Eliminar',
            sortable: false,
            renderCell: (params) => params.row.mark_remove ? !disabled && <Button startIcon={<AddCircle style={{color:'#696969'}} onClick={()=>handleAddStudy(params.row)}/>}/> : !disabled && <Button startIcon={<Delete style={{color:'#696969'}} onClick={()=>handleRemoveStudy(params.row)}/>}/>,
        }
    ];

    const data = {rows:studies || [] , columns:columns}

    return(
        <Fragment>
           <DataGridPro
                style={{backgroundColor:'white'}}
                {...data}
                headerHeight={40}
                rowHeight={40}
                disableMultipleSelection
                disableColumnMenu
                disableColumnSelector
                disableMultipleColumnsSorting
                hideFooter
            />
            
            {
                selected && 
                <SamplesDialog open={selected} onClose={handleMergeSample} study={selected} samples={samples} />
            }

        </Fragment>
   )
}
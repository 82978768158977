import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import { Print, Refresh, Visibility } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem, TextField, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { translate } from '../helpers/batchStatus';
import { createSampleRange, fetchSampleRanges, updateSampleRange } from '../state/handlers/sampleRanges';
import { clearSampleRanges } from '../state/actions/sampleRanges';
import { selectSampleRanges } from '../state/selectors/sampleRanges';
import { fetchCustomers } from '../state/handlers/catalogs.customer';
import { selectCustomers } from '../state/selectors/catalogs.customers';

const NewSampleRangeDialog = ({open, customers, onSave, onClose}) => {

    const [values, setValues] = useState({});

    const handleChange = (key,value) => {
        setValues({...values, [key]:value});
    }

    return(
        <Dialog open={open}>
            <DialogTitle>Nuevo lote</DialogTitle>
            <DialogContent>
                <div className='column-container'>
                    <TextField className='margin-top' label='Cliente' id='customer_id' select onChange={(event)=>handleChange('customer_id', event.target.value)} value={values?.customer_id || ''}>
                        {customers?.map(customer => <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>)}
                    </TextField>
                    <TextField className='margin-top' label='Cantidad' id='quantity' onChange={(event)=>handleChange('quantity', event.target.value)} value={values?.quantity || ''}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={() => onSave(values)}>ACEPTAR</Button>
                <Button color="secondary" variant="contained" onClick={() => onClose()}>CANCELAR</Button>
            </DialogActions>
        </Dialog>
    )
}

const SampleRangeDialog = ({open, sampleRange, customers, onSave, onClose}) => {
    return(
        <Dialog open={open}>
            <DialogTitle>Lote de etiquestas</DialogTitle>
            <DialogContent>
                <div className='column-container'>
                    <TextField disabled className='margin-top' label='Id'id='id' value={sampleRange.id}/>
                    <TextField disabled className='margin-top' label='Desde'id='from' value={sampleRange.from}/>
                    <TextField disabled className='margin-top' label='Hasta'id='to' value={sampleRange.to}/>
                    <TextField disabled className='margin-top' label='Estado'id='status' value={translate(sampleRange.status)}/>
                    <TextField disabled className='margin-top' label='Cliente' id='customer_id' select value={sampleRange.customer_id}>
                        {customers?.map(customer => <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>)}    
                    </TextField>
                </div>
            </DialogContent>
            <DialogActions>
                {/*<Button color="secondary" variant="contained" onClick={() => onSave(sampleRange)}>ACEPTAR</Button>*/}
                <Button color="secondary" variant="contained" onClick={() => onClose()}>CERRAR</Button>
            </DialogActions>
        </Dialog>
    )
}

const Barcode = () => {

    const dispatch = useDispatch();

    const [newSampleRange, setNewSampleRange] = useState(false);
    const [sampleRange, setSampleRange] = useState(undefined);

    useEffect(() => {
        dispatch(fetchSampleRanges());
        dispatch(fetchCustomers());
        return () => {
            dispatch(clearSampleRanges);
        }
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'number',
            hide:true,
        },
        {
            field: 'customer_id',
            headerName: 'Id de cliente',
            sortable: false,
            type: 'string',
            width: 120,
        },
        {
            field: 'from',
            headerName: 'Desde',
            sortable: true,
            flex: 0.5,
            minWidth: 110
        },
        {
            field: 'to',
            headerName: 'Hasta',
            sortable: true,
            flex: 0.5,
            minWidth: 110
        },
        {
            field: 'customer',
            headerName: 'Cliente',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            renderCell: (params) => `${params.row?.customer?.name}`,
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            flex: 0.5,
            valueGetter: (params) => translate(params.value),
            minWidth: 110
        },
        {
            field: 'print',
            headerName: 'Imprimir',
            sortable: false,
            renderCell: (params) => <Button onClick={()=>dispatch(updateSampleRange(params.row))} startIcon={<Print style={{color:'#696969'}}/>}/>,
            
        }
    ];

    const handleNew = (data) => {
        dispatch(createSampleRange(data));
        setNewSampleRange(false);
    }

    const handleUpdate = (data) => {
        dispatch(updateSampleRange(data));
        setSampleRange(undefined);
    }

    const {loading, list} = useSelector(selectSampleRanges);
    const data = {rows:list, columns:columns};

    const customers = useSelector(selectCustomers);

    return (
        <div className="column-container all-height">
            <div className="header row-container align-center">
                <div>
                    <Typography variant="h5" color="text.black">
                        <Box fontWeight={750}>Codigos de barras</Box>
                    </Typography>
                </div>
                <div className="grow"></div>
                <div className="grow margin">
                    <TextField 
                        placeholder="Buscar" 
                        variant="standard" 
                        fullWidth
                        size="small"
                    />
                </div>
                <div  className='margin'>
                    <Button 
                        variant="contained"
                        color="secondary"
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                        onClick={()=>setNewSampleRange(true)}>
                            <Typography>
                                Nuevo lote
                            </Typography>
                    </Button>
                </div>
                <div>
                    <Button 
                        variant="outlined"
                        color="primary"
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                        onClick={()=>dispatch(fetchSampleRanges())}>
                            <Refresh/>
                    </Button>
                </div>
            </div>
            <div>
                {
                    loading && <LinearProgress sx={{height:'0.2em'}}/>
                }
            </div>
            <div className="body grow padding">
                    <DataGridPro
                        {...data}
                        headerHeight={40}
                        rowHeight={40}
                        disableMultipleSelection
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleColumnsSorting
                        hideFooter
                    />
            </div>
            {
                newSampleRange && 
                <NewSampleRangeDialog open={newSampleRange} customers={customers} onSave={(data)=>handleNew(data)} onClose={()=>setNewSampleRange(false)}/>
            }

            {
                sampleRange && 
                <SampleRangeDialog open={sampleRange} sampleRange={sampleRange} customers={customers} onSave={(data)=>handleUpdate(data)} onClose={()=>setSampleRange(undefined)}/>
            }
        </div>);
}
 
export default Barcode;
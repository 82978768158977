export const actionTypes = {
    fetchCatalogs : 'FETCH_CATALOGS',
    clearCatalogs : 'CLEAR_CATALOGS',
    fetchStudies:'FETCH_STUDIES',
    receiveStudies:'RECEIVE_STUDIES',
    fetchTechnicalNote:'FETCH_TECNICAL_NOTE',
    receiveTechnicalNote:'RECEIVE_TECNICAL_NOTE',
    fetchRoutines:'FETCH_ROUTINES',
    receiveRoutines:'RECEIVE_ROUTINES',
    fetchMeasurementTypes:'FETCH_MEASUREMENT_TYPES',
    receiveMeasurementTypes:'RECEIVE_MEASUREMENT_TYPES',
    receiveMeasurementType:'RECEIVE_MEASUREMENT_TYPE',
    fetchSampleTypes:'FETCH_SAMPLE_TYPES',
    receiveSampleTypes:'RECEIVE_SAMPLE_TYPES',
    addFavorite:'ADD_FAVORITE',
    removeFavorite:'REMOVE_FAVORITE',
    fetchEfectors:'FETCH_EFECTORS',
    receiveEfectors:'RECEIVE_EFECTORS',
    fetchRoles:'FETCH_ROLES',
    receiveRoles:'RECEIVE_ROLES'
};
import React, { useEffect} from "react";
import HttpService from "../services/HttpService";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EventType} from '@azure/msal-browser';
import { logout, user } from "../state/handlers/profile";
import { selectProfile } from "../state/selectors/profile";
import { fetchCatalogs } from "../state/handlers/catalogs";

const ApiHandler = () => {

    const {instance} = useMsal();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(selectProfile);

    useEffect(() => {
        
        instance.addEventCallback(event => {

            if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS){
            
                HttpService.getInstance().setLocalToken(event.payload.idToken);
                HttpService.getInstance().setLocalUser(event.payload.account.username);
                
                dispatch(user());
            }

            if (event.eventType === EventType.SSO_SILENT_SUCCESS){

                HttpService.getInstance().setLocalToken(event.payload.idToken);
                HttpService.getInstance().setLocalUser(event.payload.account.username);
                
                dispatch(user());
            }

            if (event.eventType === EventType.LOGIN_SUCCESS){

                HttpService.getInstance().setLocalToken(event.payload.idToken);
                HttpService.getInstance().setLocalUser(event.payload.account.username);
                
                dispatch(user());
            }
        
            if (event.eventType === EventType.LOGOUT_SUCCESS){

                HttpService.getInstance().removeLocalToken();
                HttpService.getInstance().removeLocalUser();

                dispatch(logout());
            }
        });
    }, [instance]);

    useEffect(()=>{
        if (profile.data.loginSuccessful){
            dispatch(fetchCatalogs());
            navigate('dashboard');
        }
    },[profile.data.loginSuccessful])

    return (<></>)
}

export default ApiHandler;
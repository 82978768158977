import { actionTypes } from '../actions/catalogs.customer';
import HttpService from "../../services/HttpService";

const receiveCustomers = (customers) => ({ type: actionTypes.receiveCustomers, customers });
const receiveCustomer = (customer) => ({ type: actionTypes.receiveCustomer, customer });

export const setCustomer = (customer) => ({ type: actionTypes.setCustomer, customer });
export const clearCustomer = () => ({ type: actionTypes.clearCustomer});

export const fetchCustomers = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchCustomers });

        return HttpService.getInstance().getData(`resource/customers`, 'user-token')
            .then(response => {
                dispatch(receiveCustomers(response.data));
            })
    }
}

export const createCustomer = (customer) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.saveCustomer });

        return HttpService.getInstance().postData({customer:customer},`resource/customers`, 'user-token')
            .then(response => {
                dispatch(receiveCustomer(response.data));
            })
    }
}

export const updateCustomer = (customer) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.saveCustomer });

        return HttpService.getInstance().putData({customer:customer},`resource/customers/${customer.id}`, 'user-token')
            .then(response => {
                dispatch(receiveCustomer(response.data));
            })
    }
}
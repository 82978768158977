import { combineReducers } from 'redux';
import { actionTypes } from '../actions/api';

const exceptions = (state = [], action) => {
    switch (action.type) {
        case actionTypes.addApiException:
            return [...state, action.exception]
        case actionTypes.clearApiExceptions:
            return []
        default:
            return state
    }
}
export default combineReducers({ exceptions });


import React from "react";
import { Link } from "react-router-dom";
import { Button, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, parseISO } from 'date-fns';
import { Assessment, Visibility } from "@mui/icons-material";
import {progress, translate} from './../helpers/batchStatus';

import '../styles/Views.css';
import '../styles/Components.css';


const BatchCard = ({batch}) => {
    return(
        <div className="column-container batch-card">
            <div className="row-container margin-bottom">
                <div>
                    <Box fontWeight={600}>
                        <Typography color="text.gray" variant="body1">{`N° ${batch.id}`}</Typography>
                    </Box>
                </div>
                <div style={{marginLeft:'3em'}}>
                    <Box fontWeight={600}>
                        <Typography color="text.gray" variant="body1">{`${batch.customer?.name}`}</Typography>
                    </Box>
                </div>
                <div className="grow"/>
                <div>
                    <Box fontWeight={600}>
                        <Typography color="text.gray" variant="body2">{format(parseISO(batch.date), 'dd/MM/yyyy')}</Typography>
                    </Box>
                </div>
            </div>
            <div className="row-container margin-bottom align-center">
                <div className="margin-right">
                    <Box fontWeight={600}>
                        <Typography color="text.gray" variant="body2">{translate(batch.status)}</Typography>
                    </Box>
                </div>
                <div className="grow margin-right">
                    <LinearProgress sx={{height:"1em"}} variant="determinate" value={progress(batch.status)} />
                </div>
            </div>
            <div className="row-container align-center">
                <div>
                    <Link to={`/batch/${batch.id}`} style={{textDecoration:'none'}}>
                        <Button 
                            startIcon={<Visibility />} 
                            color="secondary"
                        >
                            <Box fontWeight={550}>
                                <Typography variant="body2">VER / EDITAR</Typography>
                            </Box>
                        </Button>
                    </Link>
                </div>
                <div className="grow">

                </div>
                <div>
                    <Link to={`/results?batch=${batch.id}`} style={{textDecoration:'none'}}>
                        <Button 
                            sx={{color:"black"}}
                            startIcon={<Assessment/>}
                        >
                            <Box sx={{textDecoration:'none'}} fontWeight={550}>
                                <Typography variant="body2">Resultados</Typography>
                            </Box>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BatchCard 
import React, {useEffect, useState } from 'react';

import { Assessment, Business, Dashboard, ListOutlined, ManageAccounts, Explore, QrCode2, Scale} from '@mui/icons-material';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectProfile } from "../state/selectors/profile";
import { useTheme } from '@emotion/react';
import { colorTheme } from '../ui/Theme';



const drawerWidth = '15em';

const useStyles = makeStyles((theme) => ({
    appBarRight: {
        float: 'flex',

    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        position: 'absolute',
        zIndex: 1000,

    },
    paper: {
        "&&": {
            width: drawerWidth
        }
    },
    drawerHeader: {
        ...theme.mixins.toolbar,
    },
    item:{
        marginLeft:'0.5em',
    }
}));

const Menu = ({open}) => {

    const classes = useStyles();
    const location = useLocation();
    const [route, setRoute] = useState(window.location.href);
    const profile = useSelector(selectProfile);

   
    const items = (role) => [{
            id: 'dashboard',
            label: 'Lotes',
            icon: <Dashboard/>,
            route: 'dashboard',
            scopes:['any']
        },
        {
            id: 'explorer',
            label: 'Explorador',
            icon: <Explore/>,
            route: 'explorer',
            scopes:['any']
        },
        {
            id: 'results',
            label: 'Resultados',
            icon: <Assessment/>,
            route: 'results',
            scopes:['any']
        }].filter(item=>item.scopes.includes("any") || item.scopes.includes(role));

    const settings_items = (role) => [
        {   
            id:'barcodes',
            label:'Etiquetas',
            icon:<QrCode2/>,
            route:'barcodes',
            scopes:['admin','technical']

        },
        {
            id: 'measurement',
            label: 'Requerimientos',
            icon: <Scale/>,
            route: 'measurement',
            scopes:['admin']

        },
        {
            id: 'routines',
            label: 'Perfiles',
            icon: <ListOutlined/>,
            route: 'routines',
            scopes:['customer']

        },
        {
            id: 'users',
            'label': 'Usuarios',
            icon: <ManageAccounts/>,
            route: 'users',
            scopes:['admin']

        },
        {
            id: 'customers',
            'label': 'Clientes',
            icon: <Business/>,
            route: 'customers',
            scopes:['admin']
        }].filter(item=>item.scopes.includes("any") || item.scopes.includes(role));

        
    return (

        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{ paper: classes.paper }}>

            <div className={classes.drawerHeader} />

            <List>
                {
                    items(profile.data?.role?.code).map((item, i) => (
                        <ListItem 
                            className={classes.item}
                            button 
                            key={item.id} 
                            component={Link} 
                            onClick={()=>setRoute(item.route)}
                            to={`/${item.route}`} 
                            style={location?.pathname.includes(item.route) ? {borderLeftStyle:'solid', borderLeftColor:colorTheme().secondary, borderLeftWidth:'0.2em'}:{}}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>))
                }
            </List>
            <Divider />
            <List>
            {
                settings_items(profile.data?.role?.code).map((item, i) => (
                    <ListItem 
                        className={classes.item}
                        button 
                        key={item.id} 
                        component={Link} 
                        onClick={()=>setRoute(item.route)}
                        to={`/${item.route}`} 
                        style={location?.pathname.includes(item.route) ? { borderLeftStyle:'solid', borderLeftColor:colorTheme().secondary, borderLeftWidth:'0.2em'}:{}}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItem>))
            }
            </List>
        </Drawer >)
}

export default Menu;
import { actionTypes } from '../../actions/order';
import { actionTypes as patientActionTypes } from '../../actions/patient';

const order = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.fetchOrder:
            return {}
        case actionTypes.createOrder:
            return {}
        case actionTypes.updateOrder:
            return {}
        case actionTypes.editOrder:
            return {...state, ...action.order}
        case actionTypes.clearOrder:
            return {priority:'routine',observation:''}
        case actionTypes.setOrder:
            return {...state, ...action.order}
        case actionTypes.receiveOrder:
            return action.order
        case actionTypes.receiveSimpleOrder:
                return action.order
        case patientActionTypes.receivePatient:
            return {...state, patient_id:action.patient.id}
        case patientActionTypes.setPatient:
            return {...state, patient_id:action.patient.id}
        case patientActionTypes.clearPatient:
            return {...state, patient_id:undefined}
        //case actionTypes.receiveOrder:
        //    return {...action.order, studies:[...action.order.studies.map((study,index)=>({...study,id:index, study_id: study.id }))]}
        default:
            return state
    }
}

export default order;


import { actionTypes} from '../../actions/order';
import { actionTypes as patientActionTypes } from '../../actions/patient';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchOrder:
            return true
        case actionTypes.createOrder:
            return true
        case actionTypes.updateOrder:
            return true
        case patientActionTypes.createPatient:
            return true
        case patientActionTypes.updatePatient:
            return true
        case patientActionTypes.clearPatient:
            return false
        case actionTypes.receiveOrder:
            return false
        case patientActionTypes.receivePatient:
            return false
        default:
            return state
    }
}

export default loading;
import React from 'react';
import Study from './Study';
import Studies from './Studies';
import '../../styles/Views.css';

const ResultsView = ({loading,order,samples}) => {

    return (
        <div className={"column-container"}>

                <div id="results_container" className={"column-container"} >
                    {
                        samples?.map( sample => 
                            <div key={`sample_id_${sample.id}`} className="column-container" style={{margin: '0.5em'}}>
                                {
                                    sample.studies.map(study => 
                                        <Study key={`study_id_${study.id}`} study={study} sample={sample}></Study>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <div className={"column-container"}>
                    <Studies studies={[]} />
                </div>
        </div>
    );
};

export default ResultsView;